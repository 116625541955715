/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-21 05:25:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-21 09:49:05
 * @Description: file content
 */
import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from '@material-ui/icons/Edit';
import Rating from '@material-ui/lab/Rating';
import React from 'react';
import { useStore } from "../../global/zustand";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditBlog(props) {
    const {loadBlogs, newPost} = props;
    const [open, setOpen] = React.useState(false);    
    const [post, setPost] = React.useState(newPost);
    const updateOne = useStore(s=>s.updateOne);
    const handleChange = (event) => {
        event.persist();
        setPost({...post, [event.target.name]: event.target.value });       
    };    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        loadBlogs();
        setOpen(false);
    };

    const handleUpdate = (e) => {
        e.preventDefault()
        updateOne('posts', post);
        setOpen(false);
        loadBlogs();        
    };

    if (post) {
    return (
        <div>
            <Tooltip title="Edit Post">
                <IconButton onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Update Blog"}</DialogTitle>
                <DialogContent>
                    <TextField
                        style={{ marginBottom: "2rem" }}
                        required
                        fullWidth
                        label="Title"
                        name="Title"
                        value={post.Title}
                        onChange={handleChange}
                        variant="outlined"
                    />
                    <TextField
                        style={{ marginBottom: "2rem" }}
                        required
                        fullWidth
                        label="Poster"
                        name="Poster"
                        value={post.Poster}
                        onChange={handleChange}
                        variant="outlined"
                    />

                    <TextField
                        style={{ marginBottom: "2rem" }}
                        required
                        fullWidth
                        multiline
                        rows={2}
                        label="Body"
                        name="Body"
                        value={post.Body}
                        onChange={handleChange}
                        variant="outlined"
                    />
                    <Box style={{marginLeft:"1rem"}}>
                        <Typography style={{ fontWeight: 500, marginBottom: "1rem" }} >
                            Rating:
                    </Typography>
                        <Rating
                            name="Rating"
                            value={post.Rating}
                            onChange={handleChange}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdate} color="primary">
                        Update
          </Button>
                    <Button onClick={handleClose} color="primary">
                        Close
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );}
    else {
        return (
            <div>
                Loading...
            </div>
        )
    }    
}