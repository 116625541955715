/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 07:10:33
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-28 10:58:50
 * @Description: file content
 */
import { Typography } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import ButtonBase from '@material-ui/core/ButtonBase';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import React, {useRef} from 'react';
import { useStore } from "../global/zustand";
import bannerImage from "../images/70966263_104113820983404_8081329473955299328_o.jpg";
import bannerImage3 from "../images/banner_fix.jpg";
import bannerImage2 from "../images/crissy-jarvis-gdL-UZfnD3I-unsplash.jpg";

console.log("sdfsdfsf", bannerImage)
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
const useStyles = makeStyles(theme => ({
    bannerImage: {
        background: `linear-gradient( rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.01) ),url(${bannerImage3})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100% 100%"
    },
    bannerTitle: {
        paddingTop: "5rem",
        color: "#343434",
        fontSize: "4rem",
        fontWeight: 600
    },
    bannerContainer: {
        textAlign: "center"
    },
    bannerButton: {
        marginTop: "8rem",
        [theme.breakpoints.between("lg", "xl")]: {
            width: "200px"
          },          
        [theme.breakpoints.between("sm", "md")]: {
            width: "100px"
          },    
        border: "3px solid #343434",
        display: "inline-block",
        color: "#343434",
        fontWeight: 400,
        padding: "0.5rem 0 0.5rem 0",
        transition: "0.5s",
        // '&:hover': {
        //     background: "linear-gradient( rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9) )",
        //     color: "white",
           
        //     // width: "230px",
        //     // height: "58px"
        // }
    },
    bannerButtonText: {
        [theme.breakpoints.between("lg", "xl")]: {
            fontSize: "1.5rem"
          },          
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1rem"
          },    

    },
    recoCourseText: {
        fontWeight: 500,
        color: "#343434"
    },
    recoCoursesContianer: {
        position: "relative",
        width: "100%",

    },
    recoCoursesPaper: {
        position: "absolute",
        top: "-2rem",
        borderRadius: 0,
        padding: "1rem 1.5rem 1rem 1.5rem"
        // zIndex:999
    },
    coursesContainer: {
        marginTop: "6rem"
    },
    courseItemContainer: {
        textAlign: "center"
    },
    iconContainer: {
        background: "#EFCF59",
        width: "200px",
        height: "200px",
        borderRadius: "50%",
        textAlign: "center",

    },
    icon: {
        margin: "auto",
        height: "200px",
        verticalAlign: "middle",
        color: "white",
        fontSize: "10rem"

    },
    IconImage: {
        // background: `linear-gradient( 165deg,rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0) 40% ),url()`,
        // width: "100%",
        height: "100%",
        minHeight: "250px",
        backgroundPosition: "center",
        backgroundSize: "cover",


    },
    parallax: {
        /* The image used */
        background: `url(${bannerImage2})`,

        /* Set a specific height */
        minHeight: '350px',

        /* Create the parallax scrolling effect */
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }


}));
export default function Home() {
    const classes = useStyles()
    const [blog, setBlog] = React.useState([])
    const getAll = useStore(s => s.getAll)
    const learnMore = useRef(null)
    const executeScroll = () => scrollToRef(learnMore)
    const loadBlogs = async ()=>{
        const result = await getAll("blogs")

        if (result) {
            setBlog(result)
        }
    }
    React.useEffect(()=> {loadBlogs()}, [])
    return (
        <div>
            <div className={classes.bannerImage}>
                <Box className={classes.bannerContainer}>
                    {useMediaQuery('(min-width:600px)') &&
                    <Typography className={classes.bannerTitle}>
                        IQ Sem Abacus
                    </Typography>

                    }
                    <ButtonBase className={classes.bannerButton}>
                        <Typography onClick={executeScroll} className={classes.bannerButtonText}>
                        Learn More
                        </Typography>
                    </ButtonBase>
                    
                </Box>
            </div>
            <Container>

                <Grid container
                    justify={"center"}
                    spacing={10}
                    style={{ marginBottom: "3rem" }}
                >
                    <Grid item xs={12} md={6} xl={4}>
                        <Box>
                            <Typography variant="h3">
                                <br />
                                About Us
                            </Typography>
                        </Box>
                        <Typography variant="h6" color="initial">
                            <br />

                            IQ SEM is one of the first Abacus schools in North America.

Based on decades-long experience and know-how, we have published our own workbook.

With an unique teaching method and the workbook authors' direct lecture, students are able to learn the concept of numbers and speedy calculation. 

It is an exceptional program that improves concentration and builds confidence in math! 
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} xl={4}>
                        <Box>
                            <Typography variant="h3">
                                <br />
                                학원 소개
                            </Typography>
                        </Box>
                        <Typography variant="h6" color="initial">
                            <br />
                            IQ SEM 은 북미주 최초 주산전문학원 입니다.

오랜경험과 노하우를 바탕으로 만들어낸 주산교재와 저자 직강의

재밌고 특별한 수업방식으로  수 개념을 익히고 빠른 계산력과 집중력 향상및

수학의 자신감을 가질수 있게 하는 획기적인 교육 프로그램 입니다.
                        </Typography>
                    </Grid>
                </Grid>


            </Container>
            <Box className={classes.parallax}>
                <Typography></Typography>
            </Box>
            <Container>
                <Container >

                    <Box>
                        <Typography variant="h3" align={"center"} style={{marginBottom:"2rem"}}>
                            <br />
                                Sample Videos
                            </Typography>
                    </Box>
                    <Grid container
                        justify={"space-around"}
                        style={{ marginBottom: "3rem" }}
                    >
                        <Grid item xs={12}  md={5}>
                            <iframe width="100%"  height="300px"style={{ border: "none" }}
                                src="https://www.youtube.com/embed/8mTWkwiGewk">
                            </iframe>
                        </Grid>
                    </Grid>

                </Container>
            </Container>
            <Container>
                <Container ref={learnMore} >
                    <Grid container
                        justify={"center"}
                        style={{ marginBottom: "3rem" }}
                    >
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="h3" align={"center"}>
                                    <br />
                                Reviews
                            </Typography>
                            </Box>

                        </Grid>
                        {
                            blog[0] && blog[0].Post.slice(0, 3).map((v) => {
                            return (<Grid key={v._id} item xs={12} md={4}>
                                <Box style={{ padding: "1rem" }}>


                                    <Typography variant="h5" style={{ marginBottom: "0.2rem", color: "#343434" }}>
                                        {v.Title}
                                    </Typography>

                                    <Typography style={{ fontSize: "1rem", fontWeight: 500, color: "#767676", marginBottom: "0.2rem" }}>
                                        {v.Poster}
                                    </Typography>
                                    <Rating
                                        value={v.Rating}
                                        readOnly
                                    />
                                    <Typography variant="h6" style={{ fontSize: "1rem", color: "#343434" }}>
                                        {v.Body}
                                    </Typography>
                                </Box>

                            </Grid>)
                        })

                        }

                    </Grid>
                </Container>
            </Container>
        </div>
    )
}
