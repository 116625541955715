/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 04:42:06
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-08-17 10:26:14
 * @Description: file content
 */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import React from 'react';
import {
    useHistory, useParams
} from "react-router-dom";
import { useStore } from "../global/zustand";
import EnglishPic from "../images/english-800x600.jpg";

const useStyles = makeStyles({
    // table: {
    //     minWidth: 650,
    // },
    title: {
        margin: "1rem 0 1rem 0rem",
        fontSize: "2rem",
        fontWeight: 55

    },
    createBtn: {
        height: "4rem",
        display: "flex",
        justifyContent: "flex-end"
    },
    headerContianer: {
        marginTop:"2rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    editFrom: {
        marginTop:"2rem",
        minWidth: "480px",
        minHeight: "400px",
        width:"100%",
        padding: "1.5rem 2rem 1.5rem 2rem"
    },
    courseImage: {
        background: `linear-gradient( 165deg,rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0) 40% ),url(${EnglishPic})`,
        // width: "100%",
        height: "100%",
        minHeight: "250px",
        backgroundPosition: "center",
        backgroundSize: "cover",


    },
    textBox: {
        marginBottom: "24px"
    },
    changeImageBtn: {
        margin: "1rem"
    }
});
export default function Editcourse(props) {
    const classes = useStyles();
    const [courseInfo, setcourseInfo] = React.useState({})
    let { id } = useParams();
    let history = useHistory();
    const getCourseAPI = useStore(s => s.getCourse)
    const addNoticeMessage = useStore(s => s.addNoticeMessage)
    const editCourseAPI = useStore(s => s.updateCourse)
    const createCourseAPI = useStore(s => s.createCourse)
    const getCourse = async () => {
        const result = await getCourseAPI(id)
        if (result) {
            console.log(result)
            setcourseInfo(result)
        }
    }
    const handleChange = (event) => {

        console.log(event.target.name)
        console.log(event.target.value)
        if(event&&event.target&&event.target.name&&event.target.value){
            setcourseInfo({...courseInfo, [event.target.name]: event.target.value });
        }

    };
    const createCourse = async()=>{
        const result = await createCourseAPI(courseInfo)
        if (result) {
            console.log(result)
            history.push('/managecourse')
            addNoticeMessage("New course created")
           
        }
    }
    const editCourse = async()=>{
        const result = await editCourseAPI(courseInfo._id,courseInfo)
        if (result) {
            console.log(result)
            history.push('/managecourse')
            addNoticeMessage("Edit course completed")
           
        }
    }
    React.useEffect(() => {

        if (props.option == "edit") {
            getCourse()
        }
    }, [])
    if (props.option == "edit" || props.option == "create") {
        return (
            <Container>

                <Container>
                    <Grid spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center" container>
                        <Grid item xs={12} sm={10} md={9} lg={8}>

                            {/* <Paper className={classes.editFrom} elevation={6}> */}
                                <Box className={classes.headerContianer}>
                                    {props.option == "edit" &&
                                        <Typography className={classes.title}>
                                            Edit Courses
                        </Typography>
                                    }
                                    {props.option == "create" &&
                                        <Typography className={classes.title}>
                                            Create a New Course
                        </Typography>
                                    }



                                </Box>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            onChangeCapture={handleChange}
                                            name="Title"
                                            label="Title"
                                            variant="outlined"
                                            defaultValue={props.option == "edit" ? "title" : ""}
                                            value={courseInfo.Title}

                                        />

                                    </Grid>
                                    <Grid item xs={12} md={6}>

                                        <Box className={classes.courseImage}>
                                            <Button className={classes.changeImageBtn} variant="contained" >Change Image</Button>
                                        </Box>


                                    </Grid>
                                    <Grid item xs={12} md={6}>

                                        <TextField
                                            className={classes.textBox}
                                            fullWidth
                                            onChangeCapture={handleChange}
                                            name="Price"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue={props.option == "edit" ? courseInfo.Price : 0}
                                            value={courseInfo.Price}
                                            label="Price (CAD)"
                                            variant="outlined"
                                        />
                                        <TextField
                                            className={classes.textBox}
                                            fullWidth
                                            onChangeCapture={handleChange}
                                            name="Textbook"
                                            defaultValue={props.option == "edit" ? "Textbook" : ""}
                                            value={ courseInfo.Textbook }
                                            label="Textbook"
                                            variant="outlined"
                                        />
                                        <TextField
                                            className={classes.textBox}
                                            fullWidth
                                            onChangeCapture={handleChange}
                                            name="PublicYoutubeId"
                                            defaultValue={props.option == "edit" ? "ID" : ""}
                                            value={ courseInfo.PublicYoutubeId }
                                            label="Preview Video ID"
                                            variant="outlined"
                                        />
                                        <TextField
                                            className={classes.textBox}
                                            fullWidth
                                            onChangeCapture={handleChange}
                                            name="UnlistedYoutubeId"
                        
                                    
                                            defaultValue={props.option == "edit" ? "ID" : ""}
                                            value={ courseInfo.UnlistedYoutubeId }
                                            label="Full Video ID"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} >

                                        <TextField
                                            className={classes.textBox}
                                            fullWidth
                                            defaultValue={courseInfo.Description}
                                            multiline
                                            rows={3}
                                            onChangeCapture={handleChange}
                                            defaultValue={props.option == "edit" ? "description" : ""}
                                            value={ courseInfo.Description }
                                            name="Description"
                                            label="Description"
                                            variant="outlined"
                                        />

                                    </Grid>
                                    <Grid
                                            className={classes.createBtn}
                                        item xs={12}>
                                        {props.option == "edit" &&
                                            <Button   onClick={editCourse} color="primary" >Save Your Changes</Button>
                                        }
                                        {props.option == "create" &&
                                            <Button   onClick={createCourse} color="primary" >Create</Button>
                                        }
                                    </Grid>
                                </Grid>

                            {/* </Paper> */}
                        </Grid>

                    </Grid>
                </Container>
            </Container>
        )
    } else {
        return (<div>Error</div>)
    }

}
