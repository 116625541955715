/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-21 05:25:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 17:05:16
 * @Description: file content
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { useStore } from "../../../global/zustand";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditLesson(props) {
    const [open, setOpen] = React.useState(false);    
    const [lesson, setLesson] = React.useState(props.lesson);
    const updateOne = useStore(s=>s.updateOne);
    const handleChange = (event) => {
        event.persist();
        setLesson({...lesson, [event.target.name]: event.target.value });       
    };    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
     
        setOpen(false);
    };

    const handleUpdate = (e) => {
        e.preventDefault()
        updateOne('lessons', lesson);
      
        setOpen(false);
        props.loadProduct()
    };
    if (lesson) {
    return (
        <div>
            <Tooltip title="Edit Lesson">
                <IconButton onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Update Lesson"}</DialogTitle>
                <DialogContent>
                    <TextField
                        style={{ marginBottom: "1rem" }}
                        required
                        fullWidth
                        label="Title"
                        name="Title"
                        value={lesson.Title}
                        onChange={handleChange}
                        variant="outlined"
                    />
                    <TextField
                        style={{ marginBottom: "1rem" }}
                        required
                        fullWidth
                        label="Youtube Video ID"
                        name="VideoID"
                        value={lesson.VideoID}
                        onChange={handleChange}
                        variant="outlined"
                    />
                    <TextField
                        style={{ marginBottom: "1rem" }}
                        required
                        select
                        fullWidth
                        label="Preview"
                        name="Preview"
                        value={lesson.Preview}
                        onChange={handleChange}
                        variant="outlined"
                    >
                    <MenuItem value={true}>
                    Yes
                    </MenuItem>
                    <MenuItem value={false}>
                    No
                    </MenuItem>
                    </TextField>

                    <TextField
                        style={{ marginBottom: "1rem" }}
                        required
                        fullWidth
                        multiline
                        rows={2}
                        label="Description"
                        name="Description"
                        value={lesson.Description}
                        onChange={handleChange}
                        variant="outlined"
                    />
                   

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdate} color="primary">
                        Update
          </Button>
                    <Button onClick={handleClose} color="primary">
                        Close
          </Button>
                </DialogActions>
            </Dialog>
        </div>)
    }
    else {
        return(
            <div>loading...</div>
        )
    }
}