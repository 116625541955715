/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 08:31:48
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 14:00:31
 * @Description: file content
 */
import { Typography } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Register from "./Register";
import Forgot from "./Forgot";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import IconButton from '@material-ui/core/IconButton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import BusinessIcon from '@material-ui/icons/Business';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';

const useStyles = makeStyles(() => ({
    footerContianer: {
        background: "#333A4B",
        minHeight: "300px",
        marginTop: "3rem"
    },
    footerContianerBottom: {
        background: "rgb(70,77,91)",
        minHeight: "3rem",

    },
    companyText: {
        paddingTop: ".45rem",
        color: "white",
        fontSize: "1rem",
        fontWeight: "400"
    },
    footerCol: {
        paddingTop: "2rem",
        color: "white"
    },
    footerItem: {
        // marginTop: "1.5rem",
        lineHeight: "2.5rem"
    }
}));
export default function Footer() {
    const classes = useStyles()
    return (
        <div>

        <Register/>
        <Forgot/>
            <Box className={classes.footerContianer}>
            
                <Container>

                    <Grid container>

                        <Grid className={classes.footerCol} item xs={10} md={4} >

                            <IconButton href="https://www.facebook.com/pages/category/Education/iqsem-104109274317192/"  >
                            <FacebookIcon color="primary" fontSize="large"/>
                        </IconButton>
                        <IconButton href="https://www.instagram.com/iq.sem.abacus/"  >
                            <InstagramIcon color="primary"  fontSize="large"/>
                        </IconButton>                            
                        </Grid>


                        <Grid className={classes.footerCol} item xs={10} md={4} >
                            <br/>
                            <Grid container direction="row" alignItems="center">
                                <BusinessIcon color="inherit"/>&nbsp;
                                #204-1041 Ridgeway Ave, Coquitlam
                            </Grid>
                            <br/>
                            <Grid container direction="row" alignItems="center">
                                <BusinessIcon/>&nbsp;                               
                                21130 80th Ave, Langley
                            </Grid>
                            <br/>
                            <Grid container direction="row" alignItems="center">
                                <MailOutlineIcon/>&nbsp;   
                                info@iqsemabacus.com

                            </Grid>
                            <br/>
                            <Grid container direction="row" alignItems="center">
                                <ContactPhoneIcon/>&nbsp;
                                778-240-9812

                            </Grid>
                         
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className={classes.footerContianerBottom}>
                <Container>
                    <Typography className={classes.companyText}>
                    <div>&copy;&nbsp;IQSem&nbsp;&nbsp;
                    Powered By <a href="https://xctech.dev">XCTech</a></div>
                    
                        </Typography>

                </Container>

            </Box>
        </div >
    )
}
