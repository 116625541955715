/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 04:41:39
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-21 05:28:21
 * @Description: file content
 */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "@material-ui/core/Typography";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import React from 'react';
import { useStore } from "../../global/zustand";
import VideoDialog from "./VideoDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    youtubeFrame: {
        border: "none"
    },
    table: {
        minWidth: 650,
    },
    title: {
        margin: "2rem 0 2rem 0rem",
        fontSize: "3rem",
        fontWeight: 450

    },
    createBtn: {
        height: "36px"
    },
    headerContianer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    subTitle: {
        fontSize: "1.2rem",
        margin: "1rem 0 1rem 0",
        fontWeight: 550
    },
    price: {
        fontSize: "2rem",
        margin: "1rem 0 1rem 0",
        fontWeight: 400
    },
    textbook: {
        fontSize: "1.5rem",
        margin: "1rem 0 1rem 0",
        fontWeight: 400
    },
    purchaseBtn: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    },
    previewBtn: {
        background: 'linear-gradient(45deg, #388697 30%, #3CBBB1 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(56, 134, 151, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    },
    startCourseBtn: {
        background: 'linear-gradient(45deg, #4B7F52 30%, #C0C781 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(75, 122, 82, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    }
}));

export default function Course(props) {
    const classes = useStyles()
    const upsertCart = useStore(s => s.upsertCart)
 
    return (
        <div>
            <Container>
                <Grid spacing={3}
                    direction="row"
                    justify="left"
                    alignItems="center" container>

                    <Grid item xs={12} md={8} >

                        <Box className={classes.headerContianer}>
                            <Typography className={classes.title}>
                                {props.productInfo.Name}
                            </Typography>
                        </Box>

                        <Typography className={classes.subTitle}>

                        </Typography>
                        <Box style={{
                            background: `url(${props.productInfo.ImageUrl})`, width: "auto",
                            height: "480px",
                            minHeight: "250px",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                        }} >

                        </Box>


                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Typography className={classes.subTitle}>
                            Description:
                        </Typography>
                        <Typography>
                            {props.productInfo.Description}
                        </Typography>
                        <Typography className={classes.subTitle}>
                            Price:
                        </Typography>
                        <Typography className={classes.price}>
                            ${props.productInfo.UnitPrice}
                        </Typography>
                        {!props.purchased &&
                        <Box>                            
                            <Button startIcon={<ShoppingCartIcon />} className={classes.purchaseBtn} onClick={() => { upsertCart(props.productInfo._id, props.productInfo.Type, 1) }}>Add to Cart</Button>
                        </Box>
                        }
                    </Grid>
                </Grid>
                <TableContainer component={Paper} style={{ marginTop: "1.2rem" }}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell >Title</TableCell>
                                <TableCell style={{ maxWidth: "30vw" }}>Description</TableCell>
                                <TableCell >Play</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                        { props.productInfo.Lesson && props.productInfo.Lesson.sort((a, b) => a.Title > b.Title ? 1 : -1).map((v, i) => {
                            return ( 
                            <TableRow key={i}>
                                <TableCell component="th" scope="row">
                                    {v.Title}
                                    </TableCell>
                                <TableCell style={{ maxWidth: "30vw" }}>{v.Description}</TableCell>
                                <TableCell >
                                    { (v.Preview || props.purchased) &&
                                    <VideoDialog v={v} />
                                    }
                                </TableCell>
                            </TableRow>
                        )})}
                        </TableBody>
                    </Table>
                </TableContainer>



            </Container>
        </div >
    )
}
