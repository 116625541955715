/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-31 05:17:41
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-28 12:50:51
 * @Description: file content
 */
import Button from "@material-ui/core/Button";
import Contianer from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React from 'react';
import {
    useHistory
} from "react-router-dom";
import { useStore } from "../../global/zustand";
import CartItem from "./CartItemCart";
  
const useStyles = makeStyles((theme) => ({
    cartPaper: {

        minWidth: "360px",
        marginTop: "1.2rem",
        marginLeft: "1.5rem",
        marginRight: "1.5rem",
        padding: "1.5rem",
        borderBottom: "1px solid #989898"
    },
    cartLast: {

        minWidth: "360px",
        marginTop: "1.2rem",
        marginLeft: "1.5rem",
        marginRight: "1.5rem",
        padding: "1.5rem",

    },
    checkOutBtn: {
        marginTop: "2rem",
        background: 'linear-gradient(45deg, #00A08F 30%, #124653 90%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(75, 122, 82, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    }

}));
export default function Cart() {
    const [cartDetailInfo, setCartDetailInfo] = React.useState([])
    const [subTotal, setSubTotal] = React.useState(0)
    const classes = useStyles();
    const cartCount = useStore(s => s.cartCount)
    const cart = useStore(s => s.cart)
    const getOne = useStore(s => s.getOne)
    const setLoginWindow = useStore(s => s.setLoginWindow)
    const addNoticeMessage = useStore(s => s.addNoticeMessage)
    const loginInfo = useStore(s => s.loginInfo)
    const checkOut = useStore(s => s.checkOut)
    let history = useHistory();
    const populateCart = async () => {
        if (cart.length > 0) {
            const result = await Promise.all(cart.map(async (v) => {
                const temp = await getOne("products", v._id)
                temp.count = v.count
                return temp
            }))
            console.log("change populate cart", result)
            setCartDetailInfo(result)
            let tempSubTotal = 0
            result.forEach((v) => {
                tempSubTotal += v.UnitPrice * v.count
            })
            setSubTotal(tempSubTotal)
        } else if (cart.length == 0) {
            setCartDetailInfo([])
        }
    }
    React.useEffect(()=>{populateCart()} ,[cart])
    if (cartDetailInfo.length > 0) {
        return (
            <Contianer>


                <Grid container
                    justify="center">

                    <Grid item xs={12} md={8}>
                        <Paper elevation={5} className={classes.cartPaper}>
                            <Typography variant="h4" style={{ fontWeight: 600, paddingBottom: "1.5rem", borderBottom: "2px solid #989898" }} >
                                Your Cart
            </Typography>
                            {cartDetailInfo.map((v, i) => {
                                console.log(cartCount - 1 == i)
                                return <CartItem productInfo={v} last={cartCount - 1 == i} />

                            })}

                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={5} className={classes.cartPaper}>
                            <Typography variant="h6" style={{ fontWeight: 500, paddingBottom: "1rem", borderBottom: "1px solid #989898" }}  >
                                Order Summary
                            </Typography>
                            <Typography variant="body1" color="primary" style={{ fontWeight: 500, marginTop: "1rem", marginBottom: "1rem" }}>
                                Sub total of: {cartCount} Item{cartCount > 1 ? "s" : ""}
                            </Typography>
                            <Typography variant="h6" style={{display:"block"}} >${subTotal}  
                                <Typography variant="body1" color="primary" style={{ fontWeight: 500,display:"inline-block",marginLeft:"1rem" }}>
                                    {'  + Shipping&Tax'}
                                </Typography>
                            </Typography>
                            <Button className={classes.checkOutBtn} variant="contianed" color="default" startIcon={<CheckCircleOutlineIcon />}
                                onClick={async() => {
                                    if (!loginInfo) {
                                        addNoticeMessage("Please Login to process check out!","error")
                                        setLoginWindow(true)

                                    }else{
                                        const result=await checkOut(cart)
                                        console.log(result[0])
                                        if(result[0]){
                                            history.push(`/order/${result[0]._id}`)
                                        }
                                    }
                                }}>
                                Check out
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Contianer>
        )
    } else {
        return (
            <Contianer>
                <Grid container
                    justify="center">

                    <Grid item xs={12} md={8}>
                        <Paper elevation={5} className={classes.cartPaper}>
                            <Typography variant="h6" style={{ fontWeight: 500, paddingBottom: "1rem" }}  >
                                Sorry you have no item in your cart
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Contianer>
        )
    }

}
