/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 14:42:30
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-08-17 10:21:32
 * @Description: file content
 */
import Box from "@material-ui/core/Box";
import IconButton from '@material-ui/core/IconButton';
import Paper from "@material-ui/core/Paper";
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import { useStore } from "../global/zustand";
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        "& > * + *": {
            marginTop: theme.spacing(2)
        }
    },
    messageBox: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    messageBoxContianer:{
        minWidth:"240px",
        backgroundColor:"#343434",
        color:"white",
        padding:"0.5rem 1rem 0.5rem 1rem",
        borderRadius:"5px"
    } 
}));
export default function SimpleSnackbar() {
    const classes = useStyles();
    const notice = useStore(s => s.notice)
    const removeNoticeMessage = useStore(s => s.removeNoticeMessage)

    React.useEffect(() => {
        console.log(notice)
    }, [notice ? notice.length : notice])
    if (notice && notice.length) {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={true}
                    children={<div className={classes.root}>
                        {notice ? notice.map((v) => {
                            return (
                                <Paper className={classes.messageBoxContianer} style={v.type=="success"?{backgroundColor:"#4CAF50"}:{backgroundColor:"#F44336"}}>
                                    <Box className={classes.messageBox}>
                                        {
                                            v.type=="success"&&<CheckCircleIcon/>
                                        }
                                        {
                                            v.type=="error"&&<ErrorIcon/>
                                        }
                                       
                                        

                                        <Typography>
                                            {v.message}
                                        </Typography>

                                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => { removeNoticeMessage(v.id) }}>
                                            <CloseIcon fontSize="small" />
                                        </IconButton></Box>
                                </Paper>)
                        }) : []}
                    </div>}
                // autoHideDuration={6000} 
                // onClose={() => { removeNoticeMessage(v.id)}}
                // action={

                // }


                />
            </div>
        )
    } else {
        return <></>
    }

}