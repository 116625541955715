/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-07 05:42:25
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-21 15:16:47
 * @Description: file content
 */
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Pagination from '@material-ui/lab/Pagination';
import 'moment-timezone';
import React from 'react';
import {
    Link
} from "react-router-dom";
import { useStore } from "../../global/zustand";



const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: 10,
        '&': {
            marginTop: "-10",
            height: 5,
        },
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: "#F44336",


        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const StyledTab = withStyles((theme) => ({

    root: {
        textTransform: 'none',
        color: '#767676',
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: "1.5rem",
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            opacity: 1,
        },
        '&$selected': {
            color: "#F44336",
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: "#F44336",
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        margin: "2rem 0 1rem 2rem",
        fontSize: "2rem",
        fontWeight: 55

    },
    createBtn: {
        height: "36px"
    },
    headerContianer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    statusBox: {
        color: "white",
        background: "#E63018",
        fontWeight: 500,
        display: "inline-block",

        padding: "0.2rem 0.5rem",
        borderRadius: "5px"
    },
    checkOutBtn: {
        marginTop: "2rem",
        background: 'linear-gradient(45deg, #00A08F 30%, #124653 90%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(75, 122, 82, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    },
    PaidBtn: {
        marginTop: "2rem",
        background: 'linear-gradient(45deg, #FFCF40 10%, #BF9B30 70%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(255,207,64, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    },
    headBox: {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        marginBottom: "1.5rem",
        marginTop:"1.5rem"
    },
    searchCombo: {
        display: "flex",
    },
    createNewUser: {

       background: 'linear-gradient(45deg, #006a4e 10%, #2e856e 70%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(255,207,64, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    }

});
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function Index() {
    // const loginInfo = useStore(s => s.loginInfo)
    const [roleSelect, setRoleSelect] = React.useState(1)
    const classes = useStyles()
    const search = useStore(s => s.searchSecure)
    const getOne = useStore(s => s.getOneSecure)  
    const [accounts, setAccounts] = React.useState(undefined)
    const [accountsPopulated, setAccountsPopulated] = React.useState()    
    const [page, setPage] = React.useState(1)

    const loadAccounts = async () => {
        let filter = {}
        filter.Role = roleSelect+2        
        const result = await search("accounts", undefined, 10, page, filter)
        if (result) {
            console.log("accounts:   " + result.data)
            setAccounts(result)
        }
    }

    const populateUser = async () => {
        const result = await Promise.all(accounts.data.map(async (v) => {
            const tempResult = await getOne("accounts", v._id)
            if (tempResult.Email) {
                v.User = tempResult
            }
            return v
        }))
        setAccountsPopulated({ ...accounts, data: result })
    }    
    React.useEffect(() => {
        if (accounts && accounts.data) {
            populateUser()
        }
    }, [accounts])
    React.useEffect(()=>{loadAccounts()}, [page, roleSelect])

    if (accounts && accountsPopulated) {
        return (
            <Container>
                <Box className={classes.headerContianer}>
                    <Typography className={classes.title}>
                        Users Management
                    </Typography>

                </Box>
                <StyledTabs
                    className={classes.typeSelector}
                    value={roleSelect}
                    onChange={(e, v) => {
                        console.log(v)
                        setAccountsPopulated({})
                        setRoleSelect(v)
                    }}
                    aria-label="styled tabs example"
                    TabIndicatorProps={{
                        style: {
                            height: "10px",
                        }
                    }}>
                    <StyledTab autoFocus label="Staff Users"  {...a11yProps(1)} />
                    <StyledTab label="Customers" {...a11yProps(2)} />


                </StyledTabs>
 
                <Table style={{ overflowX: 'auto' }}>

                    <TableHead>
                        <TableRow >

                            <TableCell style={{ fontWeight: 450, fontSize: "1.1rem" }}>
                                User Email:
                                </TableCell>
                            <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                                First Name:
                                </TableCell>
                            <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                                Last Name:
                                </TableCell>

                            <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                                Phone:
                                </TableCell>
                            <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                                Create Date:
                                </TableCell>
                            <TableCell >

                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {accounts && accounts.data.map((v) => {
                            return (
                                <Row key={v._id} value={v} loadAccounts={loadAccounts}/>
                            )
                        })}
                    </TableBody>

                </Table>
                <Box style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                    <Pagination page={page} onChange={(e, v) => { setPage(v) }} count={accounts.metadata.totalPages} variant="outlined" shape="rounded" />
                </Box>


            </Container>
        )
    }
    return (
        <div>
            <button onClick={loadAccounts}>reload</button>

        </div>
    )
}
function Row(props) {
    const accountId = props.value._id
    const deleteOne = useStore(s => s.deleteOne)  

    const handleDeleteUser = async (e) => {
        e.preventDefault()
        console.log("delete user", accountId)
        const result = await deleteOne('accounts', {_id: accountId})
        if(result){
            props.loadAccounts()
        }    
    }    
    return (
        <>
            <TableRow>
                <TableCell style={{ fontWeight: 500, fontSize: "1rem" }}>
                    {props.value.Email}
                                </TableCell>
                <TableCell style={{ fontWeight: 500, fontSize: "1rem" }}>
                    {props.value.Firstname}
                                </TableCell>
                <TableCell style={{ fontWeight: 500, fontSize: "1rem" }}>
                    {props.value.Lastname}
                                </TableCell>

                <TableCell style={{ fontWeight: 500, fontSize: "1rem" }}>
                    {props.value.Phonenumber}
                                </TableCell>
                <TableCell style={{ fontWeight: 500, fontSize: "1rem" }}>
                    {props.value.DateCreated}
                                </TableCell>
                <TableCell style={{ fontWeight: 500, fontSize: "1rem"}}>
                    <Tooltip title="Manage User">
                        <IconButton component={Link} to={`/user/edit/${accountId}`}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove User">
                        <IconButton onClick={handleDeleteUser} >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>

        </>
    )
}
