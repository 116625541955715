/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 04:41:39
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-22 00:51:42
 * @Description: file content
 */
import React from 'react';
import {
    useParams
} from "react-router-dom";
import { useStore } from "../../global/zustand";
import LoadingPage from "../LoadingPage";
import Course from "./Course";
import RegProduct from "./Regular";


export default function Product() {
    const [productInfo,   setProductInfo] = React.useState({})
    const [purchased, setPurchased] = React.useState(false)
    const userInfo = useStore(s => s.userInfo)
    const getOne = useStore(s => s.getOne)
    const checkPurchased = useStore(s => s.checkPurchased)
    let { id } = useParams();
    const loadProducts = async () => {
        const result = await getOne('products', id)
        if (result) {
            console.log(result)
              setProductInfo(result)
        }
    }
    const checkPurchase = async () => {
        if (userInfo) {
            const result = await checkPurchased(id)
            if (result.length>0) {
            console.log("purchased order:    " + result)
            
            
                setPurchased(true)  
            }
        }
    }
    React.useEffect(()=>{
        loadProducts()
        checkPurchase()
    }, [id])
    if(productInfo.Type==1){
        return<RegProduct  productInfo={productInfo} purchased={purchased}/>
    }else if(productInfo.Type==2){
        return <Course productInfo={productInfo} purchased={purchased}/>
    }else{
        return <LoadingPage/>
    }
    
}
