/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 09:49:40
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 14:16:32
 * @Description: file content
 */
import { IconButton } from "@material-ui/core";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';
import React from 'react';
import {
    Link,

    useHistory
} from "react-router-dom";
import { useStore } from "../global/zustand";
// import classes from '*.module.css';

export default function AlertDialogSlide() {
    const loginInfo = useStore(s => s.loginInfo)
    const logout = useStore(s => s.logout)
    const [anchorEl, setAnchorEl] = React.useState(null)
    let history = useHistory();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const [state, setState] = React.useState({
        email: "",
        remenberMe: true,
        password: "",
        checkedG: true,
    });
    // React.useEffect(() => {
    //     console.log("change")
    // }, [ loginWindowOn])
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleTextChange = (event) => {
        console.log({ [event.target.name]: event.target.value })
        setState({ ...state, [event.target.name]: event.target.value });
    };
    return (
        <div style={{ display: "inline-block" }}>

            <Tooltip title="Account">
                <IconButton fontSize="large"><SettingsIcon size="large" fontSize="large" color="primary" onClick={handleClick} /></IconButton>
            </Tooltip>
            <Menu
                id={id}
                open={open}
                style={{ marginTop: "1rem" }}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}

            >   {loginInfo.role == 2 && <div>

                <MenuItem component={Link} to="/userprofile">My account</MenuItem>
                <MenuItem component={Link} to="/orderhistory">Order History</MenuItem>
                <MenuItem component={Link} to="/courses">Purchased Course</MenuItem>
                <MenuItem component={Link} to="/ordermanagement">Orders Management</MenuItem>
                <MenuItem component={Link} to="/blogmanagement">Blogs Mangement</MenuItem>
                <MenuItem component={Link} to="/usermanagement">Users Management</MenuItem>
                <MenuItem  component={Link} to="/productmanagement">Products Mangement</MenuItem>
                <MenuItem onClick={
                    () => {
                        logout()
                        history.push("/")
                    }}>Logout</MenuItem>
            </div>}
                {loginInfo.role == 1 && <div>
                    <MenuItem component={Link} to="/userprofile">My account</MenuItem>
                    <MenuItem component={Link} to="/orderhistory">Order History</MenuItem>
                    <MenuItem component={Link} to="/courses">Purchased Course</MenuItem>
                    <MenuItem component={Link} to="/ordermanagement">Orders Management</MenuItem>
                    <MenuItem  component={Link} to="/blogmanagement">Blogs Mangement</MenuItem>
                    <MenuItem component={Link} to="/usermanagement">Users Management</MenuItem>
                    <MenuItem  component={Link} to="/productmanagement">Products Mangement</MenuItem>
                    <MenuItem onClick={
                        () => {
                            logout()
                            history.push("/")
                        }}>Logout</MenuItem>

                </div>}
                {loginInfo.role == 3 &&  <div>
                    <MenuItem component={Link} to="/userprofile">My account</MenuItem>
                    <MenuItem component={Link} to="/orderhistory">Order History</MenuItem>
                    <MenuItem component={Link} to="/courses">Purchased Course</MenuItem>
                   
                    <MenuItem onClick={
                        () => {
                            logout()
                            history.push("/")
                        }}>Logout</MenuItem>
                </div>}
            </Menu>

        </div>
    );
}
