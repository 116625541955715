/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 04:09:42
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 17:29:52
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import SnackBar from "./widget/snackBar"
import { useStore } from "./global/zustand"
import Product from "./pages/product/Product"
import Editcourse from "./pages/Editcourse"
import Managecourse from "./pages/Managecourse"
import Navbar from "./widget/navbar"
import Home from "./pages/Home"
import Footer from "./widget/footer"
import Cart from "./pages/cart/Cart"
import ProductsDisplay from "./pages/ProductsDisplay"
import SingleOrder from "./pages/singleOrder/order"
import OrderHistory from "./pages/orderHistory/Index"
import OrderManagement from "./pages/orderManagement/Index"
import ProductManagement from "./pages/productManagement/Index"
import UserManagement from "./pages/userManagement/Index"
import UserEdit from "./pages/userManagement/EditUser"
import BlogManagement from "./pages/blogManagement/Index"
import UserProfile from "./pages/UserProfile"
import Blog from "./pages/Blog"
import ProductEdit from "./pages/productManagement/ProductEdit"
import CreateProduct from "./pages/productManagement/CreateProduct"
import LessonEdit from "./pages/productManagement/Lesson/Index"
import PurchasedCourse from "./pages/PurchasedCourse"
export default function App() {
  const loadCartCount = useStore(state => state.loadCartCount)
  const loadLogin = useStore(state => state.loadLogin)
  // console.log(count)
  /**
   * boostrap loading comes to here
   */
  React.useEffect(() => {
    loadCartCount()
    loadLogin()
  }, [])
  return (
    <Router>
      <SnackBar />
      <Navbar />
      <div style={{ minHeight: "60vh" }}>


        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/managecourse">
            <Managecourse />
          </Route>
          <Route path="/products">
            <ProductsDisplay />
          </Route>
          <Route path="/course/edit/:id">
            <Editcourse option={"edit"} />
          </Route>
          <Route path="/course/create">
            <Editcourse option={"create"} />
          </Route>
          <Route path="/product/:id">
            <Product />
          </Route>
          <Route path="/order/:id">
            <SingleOrder />
          </Route>
          <Route path="/orderhistory">
            <OrderHistory />
          </Route>
          <Route path="/ordermanagement">
            <OrderManagement />
          </Route>
          <Route path="/productmanagement/create/supply">
            <CreateProduct type="Supply"/>
          </Route>
          <Route path="/productmanagement/create/course">
            < CreateProduct type="Course"/>
          </Route>
          <Route path="/productmanagement/edit/supply/:id">
            < ProductEdit type="Supply"/>
          </Route>
          <Route path="/productmanagement/edit/course/:id/lesson">
            < LessonEdit/>
          </Route>
          <Route path="/productmanagement/edit/course/:id">
            < ProductEdit type="Course"/>
          </Route>
          <Route path="/productmanagement">
            < ProductManagement/>
          </Route>
          
          <Route path="/blogmanagement">
            <  BlogManagement/>
          </Route>
          <Route path="/usermanagement">
            < UserManagement/>
          </Route>
          <Route path="/user/edit/:id">
            <UserEdit  option={"edit"}/>
          </Route>
          <Route path="/user/create">
            < UserEdit option={"create"}/>
          </Route>
          <Route path="/userprofile">
              <UserProfile/>
          </Route>
          <Route path="/reviews">
            <Blog/>
          </Route>
          <Route path="/courses">
            <PurchasedCourse/>
          </Route>
          <Route path="/cart">
            <Cart />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

