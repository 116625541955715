/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-07 13:38:32
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-21 16:03:56
 * @Description: file content
 */
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useStore } from '../../global/zustand';
import AddressEditor from "./AddressEditor";
import NewAddressBtn from "./NewAddress";

export default function Address(props) {
  const [accountInfo, setAccountInfo] = React.useState();
  const getOne = useStore(s => s.getOneSecure)
  const deleteAddress = useStore(s => s.deleteShippingAddress)  
  const accountId = props.accountId

  const handleDelete = async (e, id) => {
    e.preventDefault()
    console.log("delete address", id)
    const result = await deleteAddress(accountId, id)
    if(result){
      loadAccountInfo(accountId)
    }    
  }
  const loadAccountInfo = async () => {
    const result = await getOne('accounts',accountId)
    if (result) {
        console.log(result)
        setAccountInfo(result)
    }
  }     
  React.useEffect(()=>{loadAccountInfo()}, [])
  if (accountInfo) {
    return (
      <div style={{ margin: "1rem 0rem 2rem 0rem" }}>

        <Grid container spacing={4}>
          {accountInfo.ShippingAddress && accountInfo.ShippingAddress.length > 0 && accountInfo.ShippingAddress.map((v) => {
            return (
              <Grid key={v._id} item xs={12} md={6} style={{ marginBottom: "1.5rem" }}>
                <Box style={{ display: "flex", alignItems: "center", border: "1px solid #DBDBDB", padding: "0.5rem", borderRadius: "5px", height: "100%", marginBottom: "1rem" }}>
                  <Box style={{ display: "block" }}>
                    

                  </Box>
                  <Box>

                    <Typography style={{ fontWeight: 500, color: "rgb(63,81,181)", fontSize: "1.1rem" }}>
                      {v.Nickname}
                    </Typography>

                    <Typography style={{ fontWeight: 500 }}>
                      {v.To}
                    </Typography>
                    <Typography>
                      {v.Address}, {v.City}, {v.Province}, {v.Country} <br /> {v.PostalCode}
                    </Typography>

                   
                  </Box>
                  <IconButton onClick={(e) => handleDelete(e, v._id)} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                    < AddressEditor addressId={v._id} loadAccountInfo={loadAccountInfo}/>
                </Box>

              </Grid>)
          })}
          <Grid item xs={12} md={6} >
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #DBDBDB", padding: "1rem", borderRadius: "5px", height: "100%" }}>
              <NewAddressBtn accountId={accountId} loadAccountInfo={loadAccountInfo}/>
            </Box>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div>
      No Shipping Address
    </div>
  )
}
