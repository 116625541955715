/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 04:41:55
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-08-31 05:02:14
 * @Description: file content
 */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "@material-ui/core/Typography";
import React from 'react';
import {
    Link
} from "react-router-dom";
import { useStore } from "../global/zustand";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title:{
        margin:"2rem 0 1rem 2rem",
        fontSize:"2rem",
        fontWeight:55

    },
    createBtn:{
        height:"36px"
    },
    headerContianer:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"baseline"
    },
    
    
});
export default function Managecourse() {
    const classes = useStyles();
    const [courseList, setCourseList] = React.useState([])
    const loadCoursesAPI = useStore(state => state.getCourses)
    const deleteCoursesAPI = useStore(state => state.deleteCourse)
    const addNoticeMessage = useStore(s => s.addNoticeMessage)
    const loadCourses = async () => {
        const result = await loadCoursesAPI()
        if (result) {
            console.log(result)
            setCourseList(result)
        }
    }
    const deleteCourse= async (id)=>{
        const result = await deleteCoursesAPI(id)
        if(result){
            console.log(result)
            addNoticeMessage("Course deleted")
            loadCourses()
        }
    }
    React.useEffect(()=>{loadCourses()}, [])
    return (
        <div>
            <Container>
                <Box className={classes.headerContianer}>
                    <Typography className={classes.title}>
                        Manage Products
                    </Typography>
                    <Button className={classes.createBtn} component={Link} to={`/course/create`} color="primary" >Create a New Course</Button>
                </Box>

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell  >Course Name</TableCell>
                                <TableCell align="right">Category</TableCell>
                                <TableCell style={{maxWidth:"40vw"}} >Description</TableCell>
                                <TableCell align="right">Price</TableCell>
                                <TableCell ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {courseList.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell component="th" scope="row" ><Link to={`/course/${row._id}`}>{row.Name}</Link></TableCell>
                                    <TableCell align="right">{row.Category.Name}</TableCell>
                                    <TableCell style={{maxWidth:"40vw"}} >{row.Description}</TableCell>
                                    <TableCell align="right">${row.UnitPrice}</TableCell>
                                    <TableCell align="right">
                                        <Button component={Link} to={`/course/edit/${row._id}`} color="primary" >Edit</Button>
                                        <Button color="secondary" onClick={()=>{deleteCourse(row._id)}}>Delete</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    )
}
