/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-07 05:42:25
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-28 11:46:41
 * @Description: file content
 */
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Collapse from '@material-ui/core/Collapse';
import Container from "@material-ui/core/Container";
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PaymentIcon from '@material-ui/icons/Payment';
import Pagination from '@material-ui/lab/Pagination';
import 'moment-timezone';
import React from 'react';
import Moment from 'react-moment';
import { useStore } from "../../global/zustand";
import SingleItem from "./singleItem";


const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: 10,
        '&': {
            marginTop: "-10",
            height: 5,
        },
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: "#F44336",


        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const StyledTab = withStyles((theme) => ({

    root: {
        textTransform: 'none',
        color: '#767676',
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: "1.5rem",
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            opacity: 1,
        },
        '&$selected': {
            color: "#F44336",
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: "#F44336",
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        margin: "2rem 0 1rem 2rem",
        fontSize: "2rem",
        fontWeight: 55

    },
    createBtn: {
        height: "36px"
    },
    headerContianer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    statusBox: {
        color: "white",
        background: "#E63018",
        fontWeight: 500,
        display: "inline-block",

        padding: "0.2rem 0.5rem",
        borderRadius: "5px"
    },
    checkOutBtn: {
        marginTop: "2rem",
        background: 'linear-gradient(45deg, #00A08F 30%, #124653 90%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(75, 122, 82, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    },
    PaidBtn: {
        marginTop: "2rem",
        background: 'linear-gradient(45deg, #FFCF40 10%, #BF9B30 70%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(255,207,64, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    }


});
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function Index() {
    // const loginInfo = useStore(s => s.loginInfo)
    const [StatusTypeSelect, setStatusTypeSelect] = React.useState(1)
    const classes = useStyles()
    const userInfo = useStore(s => s.userInfo)
    const search = useStore(s => s.searchSecure)
    const getOne = useStore(s => s.getOneSecure)

    const [orderHistory, setOrderHisotry] = React.useState()
    const [orderHistoryPopulated, setOrderHisotryPopulated] = React.useState()
    const [page, setPage] = React.useState(1)
     
    // const getUserInfoWithToken = useStore(s => s.getUserInfoWithToken)
    const fetchOrderHistory = async () => {
        let filter = {}
        filter.Status = StatusTypeSelect + 1
        const result = await search("orders", undefined, 10, page, filter)
        if (result.data && result.metadata) {
            console.log(result)
            setOrderHisotry(result)
        }
    }

    const populateUser = async () => {
        const result = await Promise.all(orderHistory.data.map(async (v) => {
            const tempResult = await getOne("accountS", v.UserId)
            if (tempResult.Email) {
                v.User = tempResult
            }
            return v
        }))

        setOrderHisotryPopulated({ ...orderHistory, data: result })
    }
    React.useEffect(() => {
        if (orderHistory && orderHistory.data) {
            populateUser()
        }
    }, [orderHistory])
    React.useEffect(()=>{fetchOrderHistory()}, [userInfo, page, StatusTypeSelect])

    if (userInfo && orderHistoryPopulated) {
        return (
            <Container>
                <Box className={classes.headerContianer}>
                    <Typography className={classes.title}>
                        Order Management
                    </Typography>

                </Box>
                <StyledTabs
                    className={classes.typeSelector}
                    value={StatusTypeSelect}
                    onChange={(e, v) => {
                        console.log(v)
                        setOrderHisotryPopulated({})
                        setStatusTypeSelect(v)
                    }}
                    aria-label="styled tabs example"
                    TabIndicatorProps={{
                        style: {
                            height: "10px",
                        }
                    }}>
                    <StyledTab label="Incompleted" {...a11yProps(0)} />
                    <StyledTab autoFocus label="Pending"  {...a11yProps(1)} />
                    <StyledTab label="Shipped" {...a11yProps(2)} />
                    <StyledTab label="Delivered" {...a11yProps(3)} />
                    <StyledTab label="Returned" {...a11yProps(4)} />

                </StyledTabs>
                <Table style={{ overflowX: 'auto',maxWidth:"100vw" }}>

                    <TableHead>
                        <TableRow >
                            <TableCell>

                            </TableCell>
                            <TableCell style={{ fontWeight: 450, fontSize: "1.1rem" }}>
                                Order ID:
                                </TableCell>
                            <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                                User Email:
                                </TableCell>
                            <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                                Order Date:
                                </TableCell>

                            <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                                Order Status:
                                </TableCell>
                            <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                                Total Amount:
                                </TableCell>  
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderHistory && orderHistory.data.map((v) => {
                            return (
                                <Row value={v} fetchOrderHistory={fetchOrderHistory} />
                            )
                        })}


                    </TableBody>

                </Table>
                <Box style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                    <Pagination page={page} onChange={(e, v) => { setPage(v) }} count={orderHistory.metadata.totalPages} variant="outlined" shape="rounded" />
                </Box>


            </Container>
        )
    }
    return (
        <div>
            <button onClick={fetchOrderHistory}>reload</button>

        </div>
    )
}
function Row(props) {
    const [open, setOpen] = React.useState(false);
    const payOrder = useStore(s => s.payOrder)
    const shipOrder = useStore(s => s.shipOrder)
    const deliverOrder = useStore(s => s.deliverOrder)
    const returnOrder = useStore(s => s.returnOrder)
    const [value, setValue] = React.useState({});     
    const classes = useStyles();
    const handleChange = (event) => {
        event.persist();
        setValue({...value, [event.target.name]: event.target.value });       
    };    
    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell style={{ fontWeight: 500 }}>
                    {props.value._id}
                </TableCell>
                <TableCell style={{ fontWeight: 500 }}>
                    {props.value.User ? props.value.User.Email : "Missing User Info"}
                </TableCell>
                <TableCell>
                    <Moment style={{ fontWeight: 500 }} local format="YYYY/MM/DD HH:mm Z">
                        {props.value.OrderDate}
                    </Moment>

                </TableCell>
                <TableCell style={{ fontWeight: 500 }}>
                    {OrderStatus(props.value.Status)}
                </TableCell>
                <TableCell stylealign="right" style={{ fontWeight: 500 }}>
                    ${props.value.Amount.toFixed(2)}
                </TableCell>           
            </TableRow>
            <TableRow style={{ background: "#DBDBDB" }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit style={{ width: "100%" }}>

                        <Container>
                            <Grid container justify="center">
                                <Grid xs={12} md={8}>
                                    <Paper style={{ padding: "2rem", margin: "2rem" }} elevation={5}>
                                        <Typography variant="h5">Ordered Items</Typography>
                                        {props.value && props.value.Product && props.value.Product.length > 0 ? props.value.Product.map((v) => {
                                            return <SingleItem productInfo={v} />
                                        }) : <Typography>Sorry, no product found for this order</Typography>}
                                    </Paper>

                                </Grid>
                                <Grid xs={12} md={4}>
                                    <Paper style={{ padding: "2rem", margin: "2rem" }} elevation={5}>
                                        <Typography variant="h5" style={{ paddingBottom: "1rem" }}>Pricing Summary</Typography>
                                        <Typography style={{ fontWeight: 500 }} variant="subtitle1">
                                            Shipping Fee: ${props.value.ShippingCharge.toFixed(2)}
                                        </Typography>
                                        <Typography style={{ fontWeight: 500 }} variant="subtitle1">
                                            Tax: ${props.value.Tax.toFixed(2)}
                                        </Typography>
                                        <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                            Total Amount: ${props.value.Amount.toFixed(2)}
                                        </Typography>
                                        {props.value.Paid ? <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                            Order Paid
                                        </Typography>: <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                            Order Unpaid
                                        </Typography>}
                                        {
                                            (!props.value.Paid && (props.value.Status == 2 || props.value.Status == 3)) &&
                                            <Button className={classes.PaidBtn} onClick={() => {
                                                payOrder(props.value._id)
                                                props.fetchOrderHistory()
                                            }} variant="contianed" color="default" startIcon={<PaymentIcon />}>
                                                Mark As Paid
                                        </Button>
                                        }

                                        {/* {
                                            props.value.Status == 1 &&
                                            <Button component={Link} to={`/order/${props.value._id}`} className={classes.checkOutBtn} variant="contianed" color="default" startIcon={<CheckCircleOutlineIcon />}>
                                                Continue&nbsp;Checkout
                                        </Button>
                                        } */}

                                    </Paper>
                                    <Paper style={{ padding: "2rem", margin: "2rem" }} elevation={5}>
                                        <Typography variant="h5" style={{ paddingBottom: "1rem" }}>Shipping Information</Typography>
                                        {props.value.Shipping && props.value.Shipping.ShippingAddress ? <Box>

                                            <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                                Ship To: {props.value.Shipping.ShippingAddress.To}
                                            </Typography>
                                            <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                                Address: {props.value.Shipping.ShippingAddress.Address}, {props.value.Shipping.ShippingAddress.City},  {props.value.Shipping.ShippingAddress.Province},  {props.value.Shipping.ShippingAddress.Country}
                                            </Typography>
                                            <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                                Postal Code: {props.value.Shipping.ShippingAddress.PostalCode}
                                            </Typography>
                                            <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                                Shipper: {props.value.Shipping.Shipper}
                                            </Typography>                                            
                                            <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                                Tracking Number: {props.value.Shipping.TrackingNumber}
                                            </Typography>
                                            <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                                Shipped Date: {props.value.Shipping.ShippedDate}
                                            </Typography>
                                            <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                                Delivered Date: {props.value.Shipping.DeliveredDate}
                                            </Typography>
                                            <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                                Returned Date: {props.value.Shipping.ReturnedDate}
                                            </Typography>

                                            {  
                                                props.value.Status == 2 &&
                                                <>
                                                <TextField
                                                style={{ marginBottom: "1rem" }}
                                                required
                                                fullWidth
                                                label="Shipper"
                                                name="Shipper"
                                                value={value.Shipper}
                                                onChange={handleChange}
                                            />                                                                    
                                                <TextField
                                                style={{ marginBottom: "1rem" }}
                                                required
                                                fullWidth
                                                label="Tracking Number"
                                                name="TrackingNumber"
                                                value={value.TrackingNumber}
                                                onChange={handleChange}
                                            />                                                
                                                <Button className={classes.checkOutBtn}
                                                    onClick={() => {
                                                        shipOrder(props.value._id, value)
                                                        props.fetchOrderHistory()
                                                    }} variant="contianed" color="default" startIcon={<CheckCircleOutlineIcon />}>
                                                    Ship&nbsp;Product
                                        </Button>
                                        </>
                                            }
                                            {
                                                props.value.Status == 3 &&
                                            
                                                <Button className={classes.checkOutBtn}
                                                    onClick={() => {
                                                        deliverOrder(props.value._id)
                                                        props.fetchOrderHistory()
                                                    }} variant="contianed" color="default" startIcon={<CheckCircleOutlineIcon />}>
                                                    Deliver&nbsp;Product
                                        </Button>
                       
                                            }
                                            {
                                                props.value.Status == 4 &&
                                              
                                                <Button className={classes.checkOutBtn}
                                                    onClick={() => {
                                                        returnOrder(props.value._id)
                                                        props.fetchOrderHistory()
                                                    }} variant="contianed" color="default" startIcon={<CheckCircleOutlineIcon />}>
                                                    Return&nbsp;Product
                                        </Button>
                               
                                            }                                                                                        
                                        </Box> :
                                            <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                                No Shipping Infomation Provided
                                            </Typography>}
                                    </Paper>
                                </Grid>

                            </Grid>
                        </Container>

                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}
function OrderStatus(value) {
    const classes = useStyles()
    if (value == 1) {
        return (

            <Box className={classes.statusBox}>Incompleted</Box>

        )
    } else if (value == 2) {
        return <Box className={classes.statusBox} style={{ background: "#0080FF" }}>Pending</Box>
    } else if (value == 3) {
        return <Box className={classes.statusBox} style={{ background: "#00A86B" }}>Shipped</Box>
    } else if (value == 4) {
        return <Box className={classes.statusBox} style={{ background: "#617838" }}>Delivered</Box>
    } else if (value == 5) {
        return <Box className={classes.statusBox} style={{ background: "#767676" }}>Returned</Box>
    }
    return <Box lassName={classes.statusBox} style={{ background: "#767676" }}>N/A</Box>
}