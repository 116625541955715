/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-07 05:42:25
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 16:47:10
 * @Description: file content
 */
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import Pagination from '@material-ui/lab/Pagination';
import 'moment-timezone';
import React from 'react';
import {
    Link
} from "react-router-dom";
import { useStore } from "../../global/zustand";




const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: 10,
        '&': {
            marginTop: "-10",
            height: 5,
        },
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: "#F44336",


        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const StyledTab = withStyles((theme) => ({

    root: {
        textTransform: 'none',
        color: '#767676',
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: "1.5rem",
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            opacity: 1,
        },
        '&$selected': {
            color: "#F44336",
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: "#F44336",
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        margin: "2rem 0 1rem 2rem",
        fontSize: "2rem",
        fontWeight: 55

    },
    createBtn: {
        height: "36px"
    },
    headerContianer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    statusBox: {
        color: "white",
        background: "#E63018",
        fontWeight: 500,
        display: "inline-block",

        padding: "0.2rem 0.5rem",
        borderRadius: "5px"
    },
    checkOutBtn: {
        marginTop: "2rem",
        background: 'linear-gradient(45deg, #00A08F 30%, #124653 90%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(75, 122, 82, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    },
    PaidBtn: {
        marginTop: "2rem",
        background: 'linear-gradient(45deg, #FFCF40 10%, #BF9B30 70%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(255,207,64, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    },
    headBox: {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        marginBottom: "1.5rem",
        marginTop: "1.5rem"
    },
    searchCombo: {
        display: "flex",
    },
    createNewUser: {

        background: 'linear-gradient(45deg, #006a4e 10%, #2e856e 70%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(255,207,64, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    }



});
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function Index() {
    // const loginInfo = useStore(s => s.loginInfo)
    const [StatusTypeSelect, setStatusTypeSelect] = React.useState(0)
    const classes = useStyles()
    const search = useStore(s => s.search)
    const [products, setProducts] = React.useState()
    const [page, setPage] = React.useState(1)

    // const getUserInfoWithToken = useStore(s => s.getUserInfoWithToken)
    const loadProducts = async () => {
        let filter = {}
        filter.Type = StatusTypeSelect 
        let result
        if (StatusTypeSelect==0)
            result = await search("products", undefined, 10, page)
        else 
            result = await search("products", undefined, 10, page, filter)
        if (result.data && result.metadata) {            
            console.log(result)
            setProducts(result)
        }
    }
    React.useEffect(()=>{loadProducts()}, [page, StatusTypeSelect])

    if (products) {
    return (
        <Container>
            <Box className={classes.headerContianer}>
                <Typography className={classes.title}>
                    Product Management
                    </Typography>

            </Box>
            <StyledTabs
                className={classes.typeSelector}
                value={StatusTypeSelect}
                onChange={(e, v) => {
                    console.log(v)
                    setStatusTypeSelect(v)
                }}
                aria-label="styled tabs example"
                TabIndicatorProps={{
                    style: {
                        height: "10px",
                    }
                }}>
                <StyledTab label="All" {...a11yProps(0)} />
                <StyledTab autoFocus label="Supplies"  {...a11yProps(1)} />
                <StyledTab label="Course" {...a11yProps(2)} />
            </StyledTabs>
            <Box className={classes.headBox}>

                <Box className={classes.searchCombo}>
                    <form>
                        <TextField
                            style={{ marginRight: "1rem" }}
                            // value={searchBoxValue}
                            // onChange={(e) => { setSearchBoxValue(e.target.value) }}
                            // ref={searchBox}
                            name="search"
                            type="text"
                            label="search"
                            variant="outlined"

                            size="small"
                        ></TextField>

                        <input type="submit" hidden></input>
                    </form>
                    <Button color="primary"
                        style={{ height: "42px" }}
                        onClick={() => {

                        }}>
                        Clean Search
    </Button >
                </Box>
                <Box className={classes.searchCombo}>
                    <Button color="primary"
                        className={classes.createNewUser}
                        component={Link} to="/productmanagement/create/course"
                    >

                        Create New Course
    </Button >
                    <Button color="primary"
                        className={classes.createNewUser}
                        component={Link} to="/productmanagement/create/supply"
                    >

                        Create New Supply
    </Button >
                </Box>
            </Box>
            <Table style={{ overflowX: 'auto' }}>

                <TableHead>
                    <TableRow >

                        <TableCell style={{ fontWeight: 450, fontSize: "1.1rem" }}>
                            Product ID:
                                </TableCell>
                        <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                            Product Name
                                </TableCell>
                        <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                            Product Type
                                </TableCell>
                        <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                            Unit Price
                                </TableCell>
                                <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                            Stock
                                </TableCell>                                
                        <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>

                        </TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.data.map((v) => {
                        return (
                            <Row key={v._id} value={v} loadProducts={loadProducts}/>
                        )
                    })}

                </TableBody>

            </Table>
            <Box style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                <Pagination page={page} onChange={(e, v) => { setPage(v) }} count={products.metadata.totalPages} variant="outlined" shape="rounded" />
            </Box>


        </Container>
    )
    }
    else {
        return (
    <div>
        loading...
    </div>
        )
}

}
function Row(props) {
    const deleteOne = useStore(s => s.deleteOne)  
    const productId = props.value._id

    const handleDelete = async (e) => {
        e.preventDefault()
        console.log("delete product", productId)
        const result = await deleteOne('products', {_id: productId})
        if(result){
            props.loadProducts()
        }    
    }        
    return (
        <>
            <TableRow>

                <TableCell style={{ fontWeight: 500 }}>
                    {props.value.Name}
                </TableCell>
                <TableCell style={{ fontWeight: 500 }}>
                    {props.value.Description}
                </TableCell>
                <TableCell>
                    {(props.value.Type==1)?"Supplies":"Course"}
                </TableCell>
                <TableCell style={{ fontWeight: 500 }}>
                    {props.value.UnitPrice}
                </TableCell>
                <TableCell style={{ fontWeight: 500 }}>
                {(props.value.Type==1)?props.value.Quantity:"N/A"}
                </TableCell>
                <TableCell stylealign="right" style={{ fontWeight: 500 }}>
                    { (props.value.Type==1) &&
                    <>
                               
                    <Tooltip title="Edit Item">
                        <IconButton component={Link} to={`/productmanagement/edit/supply/${productId}`} >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    </>
                    }
                    { (props.value.Type==2) && 
                    <>    
                    <Tooltip title="Edit Item">
                        <IconButton component={Link} to={`/productmanagement/edit/course/${productId}`} >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Lesson">
                        <IconButton component={Link} to={`/productmanagement/edit/course/${productId}/lesson`} >
                            <ListIcon />
                        </IconButton>
                    </Tooltip>
                    </>
                    }
                    <Tooltip title="Delete Item">
                        <IconButton onClick={handleDelete} >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>

                </TableCell>
            </TableRow>

        </>
    )
}