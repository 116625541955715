/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-07 05:42:25
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 17:07:23
 * @Description: file content
 */
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import 'moment-timezone';
import React from 'react';
import {
    useParams
} from "react-router-dom";
import { useStore } from "../../../global/zustand";
import CreateLesson from "./CreateLesson";
import EditLesson from "./EditLesson";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        margin: "2rem 0 1rem 2rem",
        fontSize: "2rem",
        fontWeight: 55

    },
    createBtn: {
        height: "36px"
    },
    headerContianer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    statusBox: {
        color: "white",
        background: "#E63018",
        fontWeight: 500,
        display: "inline-block",

        padding: "0.2rem 0.5rem",
        borderRadius: "5px"
    },
    checkOutBtn: {
        marginTop: "2rem",
        background: 'linear-gradient(45deg, #00A08F 30%, #124653 90%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(75, 122, 82, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    },
    PaidBtn: {
        marginTop: "2rem",
        background: 'linear-gradient(45deg, #FFCF40 10%, #BF9B30 70%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(255,207,64, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    },
    headBox: {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        marginBottom: "1.5rem",
        marginTop: "1.5rem"
    },
    searchCombo: {
        display: "flex",
    },
    createNewUser: {

        background: 'linear-gradient(45deg, #006a4e 10%, #2e856e 70%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(255,207,64, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    }
});
export default function Index() {
    const classes = useStyles()
    const getOne = useStore(s => s.getOne)
    const { id } = useParams();
    const [product, setProduct] = React.useState()

    const loadProduct = async () => {
        const result = await getOne('products',id)
        if (result) {
            console.log("products:    " + result);
            setProduct(result)
        }
    }
    React.useEffect(()=>{loadProduct()}, [id])

    if (product) {
    return (
        <Container>
            <Box className={classes.headerContianer}>
                <Typography className={classes.title}>
                    Course Management
                </Typography>
                <CreateLesson productId={id} loadProduct={loadProduct}/>                

            </Box>
 
            <Table style={{ overflowX: 'auto' }}>

                <TableHead>
                    <TableRow >

                        <TableCell style={{ fontWeight: 450, fontSize: "1.1rem" }}>
                            Lesson Title:
                            </TableCell>
                        <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                            Lesson Description:
                            </TableCell>
                        <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                            Preview:
                            </TableCell>

                        <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                            Youtube Video ID:
                            </TableCell>
                        <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>

                        </TableCell>


                    </TableRow>
                </TableHead>
                <TableBody>
                    {product.Lesson.map((v, i) => {
                        return (
                            <Row key={i} value={v} loadProduct={loadProduct} productId={id}/>
                        )
                    })}


                </TableBody>

            </Table>

        </Container>
    )
    }
    else {
        return (
            <div>
                loading...
            </div>
        )
    }
}
function Row(props) {
    const deleteLesson = useStore(s => s.deleteLesson)


    const handleDelete = async (e) => {
        e.preventDefault()
        const result = await deleteLesson(props.productId, props.value._id)
        if (result) {
            props.loadProduct()
        }
    }
    return (
        <>
            <TableRow key={props.value._id}>

                <TableCell style={{ fontWeight: 500 }}>
                    {props.value.Title}
                            </TableCell>
                <TableCell style={{ fontWeight: 500 }}>
                    {props.value.Description}
                            </TableCell>
                <TableCell>
                    {(props.value.Preview)?"Yes":"No"}
                </TableCell>
                <TableCell style={{ fontWeight: 500 }}>
                    {props.value.VideoID}
                </TableCell>
                <TableCell style={{ fontWeight: 500 }}>
                    <EditLesson
                        loadProduct={props.loadProduct}
                        lesson={props.value}
                    />
                    <Tooltip title="Delete Lesson">
                        <IconButton onClick={handleDelete}  >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>

            </TableRow>

        </>
    )
}
