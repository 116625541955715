/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-08 04:06:48
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-21 16:05:38
 * @Description: file content
 */
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { useStore } from '../../global/zustand';
import countryList from 'country-list'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(() => ({

    createNewBtn: {

        background: 'linear-gradient(135deg, #D5212E 30%, #680101 90%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px #FFD1A9',
        margin: "2rem 1rem",
        transition: "1s",
        "&:hover": {

            boxShadow: '0 4px 5px 4px #FB6D4C',
        }
    }

}));

export default function EditAddress(props) {
    const {addressId, loadAccountInfo} = props
    const getOne = useStore(s => s.getOne)
    const updateOne = useStore(s => s.updateOne)
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);
    const [address, setAddress] = React.useState({})

    const handleChange = (event) => {
        event.persist();
        setAddress({...address, [event.target.name]: event.target.value });     
    }
    const handleSaveAddress = async (e) => {
        e.preventDefault()
        console.log("update address", address)
        const result = await updateOne('shippingaddress', address)
        if (result) {
            handleClose()
        }
        loadAccountInfo()
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const loadAddress = async () => {
        const result = await getOne('shippingaddress', addressId)
        if (result)
            console.log("address:    " + result);
            setAddress(result)
    };

    React.useEffect(()=>{loadAddress()}, [])

    if (address) {
    return (
        <div>
            <IconButton aria-label="delete" onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Edit  Address"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Please provide the below information to create a new address
          </DialogContentText>

                </DialogContent>
                <form className={classes.root} autoComplete="off" onSubmit={handleSaveAddress}>
                    <DialogContent>
                        <Grid container spacing={2}>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Address Nickname"
                                    name="Nickname"
                                    variant="outlined"
                                    value={
                                        address.Nickname === null ||
                                        address.Nickname === undefined
                                        ? ""
                                        : address.Nickname}
                                    onChange={handleChange}
                                    helperText="eg. Peter's home"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="To"
                                    name="To"
                                    value={
                                        address.To === null ||
                                        address.To === undefined
                                        ? ""
                                        : address.To}
                                    onChange={handleChange}
                                    variant="outlined"
                                    helperText="eg. Peter"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Street"
                                    name="Address"
                                    variant="outlined"
                                    value={
                                        address.Address === null ||
                                        address.Address === undefined
                                        ? ""
                                        : address.Address}
                                    onChange={handleChange}

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="City"
                                    name="City"
                                    variant="outlined"
                                    value={
                                        address.City === null ||
                                        address.City === undefined
                                        ? ""
                                        : address.City}
                                    onChange={handleChange}

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Province"
                                    name="Province"
                                    variant="outlined"
                                    value={
                                        address.Province === null ||
                                        address.Province === undefined
                                        ? ""
                                        : address.Province}
                                    onChange={handleChange}

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    select
                                    fullWidth
                                    id="outlined-required"
                                    label="Country"
                                    name="Country"
                                    variant="outlined"
                                    value={
                                        address.Country === null ||
                                        address.Country === undefined
                                        ? "CA"
                                        : address.Country}
                                    onChange={handleChange}

                                >
                                    {countryList.getNames().sort().map((n) => { 
                                    return (
                                    <MenuItem value={countryList.getCode(n)}>
                                            {n}
                                    </MenuItem>
                                    )
                                    })}
                                    </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField                                  
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="PostalCode"
                                    name="PostalCode"
                                    variant="outlined"
                                    value={
                                        address.PostalCode === null ||
                                        address.PostalCode === undefined
                                        ? "CA"
                                        : address.PostalCode}
                                    onChange={handleChange}

                                />
                                
 
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
          </Button>
                        <Button type="submit" color="primary">
                            Update
          </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>


    )
    }
}
