/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 06:26:04
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-28 11:48:21
 * @Description: file content
 */
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import Badge from '@material-ui/core/Badge';
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import React from "react";
import {
    Link
} from "react-router-dom";

import MenuIcon from "@material-ui/icons/Menu";
import Login from "./Login"
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useStore } from "../global/zustand"
import AccountBtn from "./AccountBtn"
import logoImage from "../images/iqsem-logo.png"
import Register from "./Register"
import Forgot from "./Forgot"
import Drawer from "./drawer"

const useStyles = makeStyles((theme) => ({
    navBar: {
        position: "sticky",
        top: 0,
        zIndex: 2,
        boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.06), 0px 6px 10px 0px rgba(0,0,0,0.02), 0px 1px 18px 0px rgba(0,0,0,0.03)"

    },
}));
export default function App() {
    // const [cartCount,setCount]=React.useState(0)
    const cartCount = useStore(s => s.cartCount)
    const loginInfo = useStore(s => s.loginInfo)
    const classes = useStyles()
    return (
        <>
        <Box className={classes.navBar} bgcolor="white" p={2}>
           
            <Container style={{display:"flex",alignItems:"center"}}>

                <Button size="medium" component={Link} to={"/"} color="primary">
                <img alt="" src={logoImage} style={{height:"4.5rem",width:"auto"}}/>
                </Button>
                
                <Box flexGrow={1} style={{display:"flex",justifyContent:"space-between",flexDirection:"row-reverse"}}>
                
                    <Box textAlign="right"  >
                   
                        <Button className={classes.menuItems}  size="large" component={Link} to={"/"} color="primary"><Typography variant="h6" color="initial">Home</Typography></Button>
                        <Button className={classes.menuItems}  size="large" component={Link} to={"/products"} color="primary"><Typography variant="h6" color="initial">Shop</Typography></Button>
                        <Button  className={classes.menuItems}  size="large"component={Link} to={"/reviews"} color="primary"><Typography variant="h6" color="initial">Reviews</Typography> </Button>


                        <Tooltip title="View Cart">

                            <IconButton size="medium" component={Link} to={"/cart"} ><Badge badgeContent={cartCount} color="secondary"><ShoppingCartIcon  fontSize="large" color="primary" /> </Badge></IconButton>

                        </Tooltip>
                        {loginInfo ? <AccountBtn /> : <Login />}

                    </Box>
                </Box>
            </Container>
            
        </Box>
      
        </>
    );
}