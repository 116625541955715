/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 09:49:40
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 14:00:44
 * @Description: file content
 */
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useStore } from "../global/zustand";
// import classes from '*.module.css';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function Forgot() {
    const setForgotWindow = useStore(s => s.setForgotWindow)
    const sendPassword = useStore(s => s.sendPassword)
    const forgotWindowOn = useStore(s => s.forgotWindowOn)
    const fields = [
        { fieldname: 'Email', required: true }
    ]
 
    const [state, setState] = React.useState({
     
    });
    React.useEffect(()=>{
    },[forgotWindowOn])


    const handleTextChange = (event) => {
        console.log({ [event.target.name]: event.target.value })
        setState({ ...state, [event.target.name]: event.target.value });
    };
    return (
        <div style={{ display: "inline-block" }}>

            

            <Dialog
                fullWidth
                maxWidth={"xs"}
                open={forgotWindowOn}
                TransitionComponent={Transition}
                keepMounted
                onClose={()=>{setForgotWindow(false)}}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Forgot Password"}</DialogTitle>
                <DialogContent>
                    {fields.map((v, i) => {
                        return (
                            <DialogContentText key={i} id="alert-dialog-slide-description">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    required={v.required}
                                    name={v.fieldname}
                                    value={state[v.fieldname]}
                                    onChange={handleTextChange}
                                    id="standard-required"
                                    label={v.displayname?v.displayname:v.fieldname}
                                    defaultValue=""
                                />

                            </DialogContentText>
                        )
                    })}

                 
                <Typography style={{fontSize:"0.8rem"}}>Please enter the email that was used for registration.</Typography>

            
                </DialogContent>
                <DialogActions>

                    <Button onClick={() => { setForgotWindow(false) }} color="primary">
                        Close
          </Button>
                    <Button onClick={
                        async () => {
                            const loginResult = await sendPassword(state)
                            console.log(loginResult)
                            if (loginResult) {
                                const sent = setForgotWindow(false)
                                if (sent) alert("Password sent!!")
                            }
                        }} color="primary">
                        Send
          </Button>
                    
                </DialogActions>
            </Dialog>
        </div>
    );
}
