import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {
    Link
} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        margin: "auto"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    price: {
        fontSize: "1.5rem",
        marginRight: "0.2rem",
        color: "#343434"
    },
    cardActions: {
        display: "flex",
        justifyContent: "space-between"
    }
}));

export default function PurchasedCourseCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={6}>
            <CardActionArea component={Link} to={`/product/${props.courseInfo._id}`}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            {props.courseInfo.Name.substring(0, 1)}
                        </Avatar>
                    }

                    title={props.courseInfo.Name}
                    subheader={props.courseInfo.Type==1?"Textbook & Tool":"Online Course"}
                />
                <CardMedia
                    className={classes.media}
                    image={props.courseInfo.ImageUrl}
                    title="Paella dish"
                />

                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {props.courseInfo.Description}
                    </Typography>
                </CardContent>
            </CardActionArea>
           
        </Card>
    );
}
