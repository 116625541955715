/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 04:42:06
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 16:24:08
 * @Description: file content
 */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import React from 'react';
import {
    useParams
} from "react-router-dom";
import { useStore } from "../../global/zustand";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    // table: {
    //     minWidth: 650,
    // },
    title: {
        margin: "1rem 0 1rem 0rem",
        fontSize: "2rem",
        fontWeight: 55

    },
    createBtn: {
        height: "4rem",
        display: "flex",
        justifyContent: "flex-end"
    },
    headerContianer: {
        marginTop: "2rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    editFrom: {
        marginTop: "2rem",
        minWidth: "480px",
        minHeight: "400px",
        width: "100%",
        padding: "1.5rem 2rem 1.5rem 2rem"
    },

    textBox: {
        marginBottom: "24px"
    },
    changeImageBtn: {
        margin: "1rem"
    }
});
export default function UserProfile(props) {
    const { type } = props
    const classes = useStyles()
    const addOne = useStore(s => s.addOne)   
    const getAll = useStore(s => s.getAll)    
    const [value, setValue] = React.useState({});
    const [category, setCategory] = React.useState()
    const history = useHistory()
    const handleChange = (event) => {
        event.persist();
        setValue({...value, [event.target.name]: event.target.value });     
    };

    const handleCreate = async (e) => {
        e.preventDefault()
        console.log("Create product", value)
        await addOne('products', value)
        history.push('/productmanagement')
    }          

    const loadCategory = async () => {
        const result = await getAll('category')
        
        if (result) {
            setCategory(result)
        }
    }    
    
    React.useEffect(() => {
        loadCategory()
        if (type)
            (type=="Course")?setValue({...value, Type:2}):setValue({...value, Type:1})
    }, [])

    return (
        <Container>

            <Container>
                <Grid spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center" container>
                    <Grid item xs={12} sm={10} md={9} lg={8}>

                        {/* <Paper className={classes.editFrom} elevation={6}> */}
                        <Box className={classes.headerContianer}>

                            <Typography className={classes.title}>
                            Create {props.type}
                            </Typography>

                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    onChange={handleChange}
                                    name="Name"
                                    label=" "
                                    variant="outlined"
                                    value={value.Name}

                                    label="Name"

                                />

                            </Grid>

                            <Grid item xs={12}>

                                <TextField
                                    className={classes.textBox}
                                    fullWidth
                                    onChange={handleChange}
                                    name="UnitPrice"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    // defaultValue={props.option == "edit" ? userInfo.FirstName : ""}
                                    value={value.UnitPrice}
                                    label="Price"
                                    variant="outlined"
                                />
                                <TextField
                                    className={classes.textBox}
                                    fullWidth
                                    onChange={handleChange}
                                    name="Description"
                                    multiline={true}
                                    rows={2}
                                    rowsMax={4}
                                    label="Description"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    // defaultValue={props.option == "edit" ? userInfo.FirstName : ""}
                                    // value={userInfo.Lastname}
                                    value={value.Description}
                                    variant="outlined"
                                />
                                <TextField
                                    className={classes.textBox}
                                    fullWidth
                                    onChange={handleChange}
                                    name="ImageUrl"

                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    // defaultValue={props.option == "edit" ? userInfo.FirstName : ""}
                                    // value={userInfo.Phonenumber}
                                    label="Image URL"
                                    variant="outlined"
                                    value={value.ImageUrl}
                                />
                                {value.Type==1 && 
                               <TextField
                                    className={classes.textBox}
                                    fullWidth
                                    onChange={handleChange}
                                    name="Quantity"

                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    // defaultValue={props.option == "edit" ? userInfo.FirstName : ""}
                                    // value={userInfo.Phonenumber}
                                    label="Stock Count"
                                    variant="outlined"
                                    value={value.Quantity || 1}
                                />            
                                }                    
                                <TextField
                                    className={classes.textBox}
                                    fullWidth
                                    select
                                    onChange={handleChange}
                                    name="CategoryId"
                                    // defaultValue={props.option == "edit" ? "ID" : ""}
                                    value={value.CategoryId}
                                    label="Category"
                                    variant="outlined"                                 
                                >

                                    {category && 
                                        category.map( (v) => {
                                        return (
                                            <MenuItem value={v._id}>
                                                {v.Name}
                                            </MenuItem>
                                        )
                                    })
                                    }
                                </TextField>
                            </Grid>

                            <Grid
                                className={classes.createBtn}
                                item xs={12}>

                                <Button onClick={handleCreate} color="primary" >Create</Button>


                            </Grid>

                        </Grid>
                        {/* <AddressManager accountId={userInfo._id}/> */}
                        {/* </Paper> */}
                    </Grid>

                </Grid>

            </Container>
        </Container>
    )
 
}
