/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-31 03:03:24
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-08-31 03:04:24
 * @Description: file content
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
   margin:"auto"
  },
}));

export default function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
     
    </div>
  );
}