/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 07:10:33
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 17:34:25
 * @Description: file content
 */
import { Typography } from '@material-ui/core';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useStore } from "../global/zustand";
import banner from "../images/pexels-pixabay-159711.jpg";
import CourseCard from "../widget/PurchasedCourseCard";

const useStyles = makeStyles({
    bannerImage: {
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2) ),url(${banner})`,
        width: "100%",
        height: "500px",
        backgroundPosition: "center",
        backgroundSize: "cover"
    },
    bannerTitle: {
        paddingTop: "5rem",
        color: "white",
        fontSize: "3rem",
        fontWeight: 600
    },
    bannerContainer: {
        textAlign: "center"
    },
    bannerButton: {
        marginTop: "8rem",
        width: "200px",
        border: "3px solid white",
        display: "inline-block",
        color: "white",
        fontWeight: 400,
        padding: "0.5rem 0 0.5rem 0",
        transition: "0.5s",
        '&:hover': {
            background: "linear-gradient( rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9) )",
            color: "#343434",
            border: "none",
            width: "230px",
            height: "58px"
        }
    },
    bannerButtonText: {
        fontSize: "1.5rem",
    },
    recoCourseText: {
        fontWeight: 500,
        color: "#343434"
    },
    recoCoursesContianer: {
        position: "relative",
        width: "100%",

    },
    recoCoursesPaper: {
        position: "absolute",
        top: "-2rem",
        borderRadius: 0,
        padding: "1rem 1.5rem 1rem 1.5rem"
        // zIndex:999
    },
    coursesContainer: {
        marginTop: "2rem"
    },
    courseItemContainer: {
        textAlign: "center"
    },
    iconContainer: {
        background: "#EFCF59",
        width: "200px",
        height: "200px",
        borderRadius: "50%",
        textAlign: "center",

    },
    icon: {
        margin: "auto",
        height: "200px",
        verticalAlign: "middle",
        color: "white",
        fontSize: "10rem"

    },
    headBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "1.5rem"
    },
    searchCombo: {
        display: "flex",
    },
    categorySelector: {
        minWidth: "180px"
    },
    formControl: {
        margin: "1rem",
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: "6rem",
    },
    typeSelector: {
        marginBottom: "1rem"
    },
    bigTitle: {
        fontWeight: 700,
        color: "#343434",
        marginLeft: "1.5rem",
        paddingBottom: "2rem",
        // marginBottom:"rem",
        // borderBottom: "2px solid #767676"

    }



});

export default function Home() {
    const classes = useStyles()
    const userInfo = useStore(s=>s.userInfo)
    const getOne    = useStore(s=>s.getOneSecure)
    const [courses, setCourses] = React.useState()    
    const loadCourses = async () => {
        const result = await getOne('accounts', userInfo._id)
        if (result) 
            setCourses(result.Course);
    }
    React.useEffect(() => {
        loadCourses()
    }, [])

    if (courses)
    return (
        <div>
            <Container className={classes.coursesContainer}>
                <Typography variant="h3" className={classes.bigTitle}>
                    Purchased Course
                </Typography>
               
                <Container >

                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center">
                        {courses && courses.map((v, i) => {
                            return (
                                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                                    <CourseCard courseInfo={v} />
                                </Grid>)
                        })}
                    </Grid>

                </Container>
            </Container>
        </div>
    )
    else {
        return (
            <div>
                loading...
            </div>
        )
    }
}
