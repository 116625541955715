/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 07:10:33
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 13:14:17
 * @Description: file content
 */
import { Typography } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import React from 'react';
import { useStore } from "../global/zustand";
import bannerImage3 from "../images/banner_fix.jpg";

const useStyles = makeStyles({
    bannerImage: {
        background: `linear-gradient( rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.01) ),url(${bannerImage3})`,
        width: "100%",
        height: "280px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        marginBottom:"2rem"
    },
    bannerTitle: {
        paddingTop: "5rem",
        color: "#343434",
        fontSize: "4rem",
        fontWeight: 600
    },
    bannerContainer: {
        textAlign: "center"
    },
    bannerButton: {
        marginTop: "8rem",
        width: "200px",
        border: "3px solid #343434",
        display: "inline-block",
        color: "#343434",
        fontWeight: 400,
        padding: "0.5rem 0 0.5rem 0",
        transition: "0.5s",
        // '&:hover': {
        //     background: "linear-gradient( rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9) )",
        //     color: "white",
           
        //     // width: "230px",
        //     // height: "58px"
        // }
    },
    bannerButtonText: {
        fontSize: "1.5rem",
    },
    recoCourseText: {
        fontWeight: 500,
        color: "#343434"
    },
    recoCoursesContianer: {
        position: "relative",
        width: "100%",

    },
    recoCoursesPaper: {
        position: "absolute",
        top: "-2rem",
        borderRadius: 0,
        padding: "1rem 1.5rem 1rem 1.5rem"
        // zIndex:999
    },
    coursesContainer: {
        marginTop: "6rem"
    },
    courseItemContainer: {
        textAlign: "center"
    },
    iconContainer: {
        background: "#EFCF59",
        width: "200px",
        height: "200px",
        borderRadius: "50%",
        textAlign: "center",

    },
    icon: {
        margin: "auto",
        height: "200px",
        verticalAlign: "middle",
        color: "white",
        fontSize: "10rem"

    },
    IconImage: {
        // background: `linear-gradient( 165deg,rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0) 40% ),url()`,
        // width: "100%",
        height: "100%",
        minHeight: "250px",
        backgroundPosition: "center",
        backgroundSize: "cover",


    },
});
export default function Home() {
    const classes = useStyles()
    const [blog, setBlog] = React.useState([])
    const getAll = useStore(s => s.getAll)

    const loadBlogs = async ()=>{
        const result = await getAll("blogs")

        if (result) {
            setBlog(result)
        }
    }
    React.useEffect(()=>{loadBlogs()}, [])
    if (blog) {
    return (
        <div>
            <div className={classes.bannerImage}>
                <Box className={classes.bannerContainer}>
                    <Typography className={classes.bannerTitle}>
                        Reviews
                    </Typography>
                </Box>
            </div>
 
            
            <Container>
                <Container >
                    <Grid container
                        justify={"center"}
                        style={{ marginBottom: "3rem" }}
                    >
                         <br /> <br />
                        { blog[0] && blog[0].Post.map((v) => {
                            return (<Grid item xs={12} md={4}>
                                <Box style={{ padding: "1rem" }}>


                                    <Typography variant="h5" style={{ marginBottom: "0.2rem", color: "#343434" }}>
                                        {v.Title}
                                    </Typography>

                                    <Typography style={{ fontSize: "1rem", fontWeight: 500, color: "#767676", marginBottom: "0.2rem" }}>
                                        {v.Poster}
                                    </Typography>
                                    <Rating
                                        value={v.Rating}
                                        readonly
                                    />
                                    <Typography variant="h6" style={{ fontSize: "1rem", color: "#343434" }}>
                                        {v.Body}
                                    </Typography>
                                </Box>

                            </Grid>)
                        })}

                    </Grid>
                </Container>
            </Container>

        </div>
    )
    }
    else {
        return (
            <div>loading..</div>
        )
    }
}
