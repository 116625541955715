/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 04:41:39
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-08-31 05:04:24
 * @Description: file content
 */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container"
import { useStore } from "../../global/zustand"
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TextField from '@material-ui/core/TextField';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    youtubeFrame: {
        border: "none"
    },
    table: {
        minWidth: 650,
    },
    title: {
        margin: "2rem 0 2rem 0rem",
        fontSize: "3rem",
        fontWeight: 450

    },
    createBtn: {
        height: "36px"
    },
    headerContianer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    subTitle: {
        fontSize: "1.2rem",
        margin: "1rem 0 1rem 0",
        fontWeight: 550
    },
    price: {
        fontSize: "2rem",
        margin: "1rem 0 1rem 0",
        fontWeight: 400
    },
    textbook: {
        fontSize: "1.5rem",
        margin: "1rem 0 1rem 0",
        fontWeight: 400
    },
    purchaseBtn: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        marginRight: "1rem",

    },
    previewBtn: {
        background: 'linear-gradient(45deg, #388697 30%, #3CBBB1 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(56, 134, 151, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    },
    startCourseBtn: {
        background: 'linear-gradient(45deg, #4B7F52 30%, #C0C781 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(75, 122, 82, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    },
    stockIndicator: {
        borderRadius: "6px",
        color: "white",
        fontWeight: 500,
        display: "inline",
        fontSize: "1rem",
        padding: "0.2rem 0.4rem 0.2rem 0.4rem"
    },
    countInputBox: {
        width: "80px",
        marginRight: "1rem"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    courseImage: {
        width: "auto",
        height: "480px",
        minHeight: "250px",

        backgroundPosition: "center",
        backgroundSize: "cover",


    },
}));


export default function Course(props) {
    const classes = useStyles()
    const upsertCart = useStore(s => s.upsertCart)
    const [count, setCount] = React.useState(1);

    const stockIndicator = () => {
        let color = ""
        let text = ""
        if (props.productInfo.Quantity) {
            if (props.productInfo.Quantity > 10) {
                color = "#617B30"
                text = "In Stock"

            } else if (props.productInfo.Quantity > 0) {
                color = "#FFD11D"
                text = "Low in stock (Delivery might take 1 or 2 weeks)"
            } else {
                color = "#810020"
                text = "Temporary Out of Stock"
            }
        } else {
            color = "#767676"
            text = "No Stock Info"
        }
        return <Box style={{ backgroundColor: color, fontWeight: 500 }} className={classes.stockIndicator}>{text}</Box>
    }
    return (
        <div>
            <Container>
                <Grid spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center" container>

                    <Grid item xs={12} md={8} >

                        <Box className={classes.headerContianer}>
                            <Typography className={classes.title}>
                                {props.productInfo.Name}
                            </Typography>
                        </Box>

                        <Typography className={classes.subTitle}>

                        </Typography>
                        <Box style={{
                            background: `url(${props.productInfo.ImageUrl})`, width: "auto",
                            height: "480px",
                            minHeight: "250px",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                        }} >

                        </Box>


                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Typography className={classes.subTitle}>
                            Description:
                        </Typography>
                        <Typography>
                            {props.productInfo.Description}
                        </Typography>
                        <Typography className={classes.subTitle}>
                            Stock:
                        </Typography>

                        {stockIndicator()}

                        <Typography className={classes.subTitle}>
                            Price:
                        </Typography>
                        <Typography className={classes.price}>
                            ${props.productInfo.UnitPrice}
                        </Typography>
                        <Box>

                            <TextField
                                className={classes.countInputBox}
                                type="number" size="small" value={count}
                                onChange={(e) => {
                                    if (e.target.value >= 1) {
                                        setCount(e.target.value)
                                    } else {
                                        setCount(1)
                                    }
                                }}
                                id="outlined-basic"
                                label="Count"
                                variant="outlined" />
                            { props.productInfo.Quantity>0 &&
                            <Button startIcon={<ShoppingCartIcon />} className={classes.purchaseBtn} onClick={() => { upsertCart(props.productInfo._id, props.productInfo.Type, count) }}>Add to Cart</Button>
                            }
                            {props.purchased &&
                            <div>* You have already purchased this item.</div>
                            }

                        </Box>
                    </Grid>




                </Grid>



            </Container>
        </div >
    )
}
