/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-07 05:15:16
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-08 07:31:07
 * @Description: file content
 */
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import 'moment-timezone';
import React from 'react';
import {
    Link,
    useHistory,
    useParams
} from "react-router-dom";
import { useStore } from "../../global/zustand";
import AddressSelector from "../../widget/Address/AddressSelector";
import SingleItem from "./singleItem";
import Confirmation from "./confirmation";
import { PayPalButton } from "react-paypal-button-v2";

const useStyles = makeStyles((theme) => ({
    cartPaper: {

        minWidth: "360px",
        marginTop: "1.2rem",
        marginLeft: "1.5rem",
        marginRight: "1.5rem",
        padding: "1.5rem",
        borderBottom: "1px solid #989898"
    },
    cartLast: {

        minWidth: "360px",
        marginTop: "1.2rem",
        marginLeft: "1.5rem",
        marginRight: "1.5rem",
        padding: "1.5rem",

    },
    checkOutBtn: {
        marginTop: "2rem",
        background: 'linear-gradient(45deg, #00A08F 30%, #124653 90%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(75, 122, 82, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    }

}));

const PRODUCTION_CLIENT_ID = "AcIOCdSomLBazNiVl1QGB4XG4dw1dcwgRdoY9nV2u7__1MxdF8sekiykCynuNShlAK-eLZruo734T-JR"
// const PRODUCTION_CLIENT_ID = "AbMG_2Jg2LLPdrVbiSP1fyGPCSI98BAclJrP2USs64VGusIzWpx5939DcL2wfA95WUgbsG0TUQAuIo-8"
export default function Order() {
    const userInfo = useStore(s => s.userInfo)    
    const classes = useStyles()
    const history = useHistory()
    let { id } = useParams();
    let getOne = useStore(s => s.getOne)
    let completeOrder = useStore(s => s.completeOrder)
    let payOrder = useStore(s => s.payOrder)
    const [orderInfo, setOrderInfo] = React.useState()
    const [selectAddress, setSeletedAddress] = React.useState(null)
    const getOrder = async () => {
        const result = await getOne("orders", id)
        if (result.Status) {
            setOrderInfo(result)
        } else {

        }
    }
    const handleCompleteCheckout=async()=>{
        const result=await completeOrder(orderInfo, selectAddress)
        console.log(result)
       if(result){
           getOrder()
       }
    }

    React.useEffect(()=>{
        if (selectAddress!=null) {
            if (!["CA", "US"].includes(selectAddress.Country)) {
 //               let tax = (orderInfo.Amount - orderInfo.Tax + 20)*0.12
 //               let total =  orderInfo.Amount - orderInfo.Tax + 20 + tax
                let shippingCharge = 0
                for (let product of orderInfo.Product) {
                    if (product.Type==1)
                        shippingCharge += 20*product.Quantity
                }
                let total = orderInfo.Amount + shippingCharge
                setOrderInfo({...orderInfo, ShippingCharge: shippingCharge, Amount: total});
            }
        }
    }, [selectAddress])
    React.useEffect(()=>{getOrder()}, [id])
    if (orderInfo)
        return (
            <Container>
                <Grid container justify="center">
                    <Grid xs={12} md={8}>
                        <Paper style={{ padding: "2rem", margin: "2rem" }} elevation={5}>
                            <Typography variant="h5">Ordered Items</Typography>
                            {orderInfo && orderInfo.Status == 1 && orderInfo.Product ? orderInfo.Product.map((v) => {
                                return <SingleItem productInfo={v} />
                            }) : orderInfo.Product.map((v) => {
                                return <Confirmation productInfo={v} />
                            })
                            }
                        </Paper>
                        {
                            orderInfo.Status == 1 &&
                        <Paper style={{ padding: "2rem", margin: "2rem" }} elevation={5}>
                            <Typography variant="h5" style={{ paddingBottom: "1rem" }}>Shipping Information</Typography>
                            <AddressSelector accountId={userInfo._id} setAddress={setSeletedAddress} />
                        </Paper>
                        }
                    </Grid>
                    <Grid xs={12} md={4}>
                        <Paper style={{ padding: "2rem", margin: "2rem" }} elevation={5}>
                            <Typography variant="h5" style={{ paddingBottom: "1rem" }}>Pricing Summary</Typography>
                            <Typography style={{ fontWeight: 500 }} variant="subtitle1">
                                Shipping Fee: ${orderInfo.ShippingCharge.toFixed(2)}
                            </Typography>
                            <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                Total Amount: ${orderInfo.Amount.toFixed(2)}
                            </Typography>
                            <Typography>
                                {selectAddress ? (<Box>
                                    <Typography variant="h5" style={{ margin: "1rem 1rem 1rem 0" }}>Shipping Information</Typography>
                                    <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                        Ship To: {selectAddress.To}
                                    </Typography>
                                    <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                        Address: {selectAddress.Address}, {selectAddress.City},  {selectAddress.Province},  {selectAddress.Country}
                                    </Typography>
                                    <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                        Postal Code: {selectAddress.PostalCode}
                                    </Typography>
                                </Box>) :
                                    <Box>
                                        <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                            No Shipping Infomation Provided
                                        </Typography>

                                    </Box>}
                            </Typography>
                            {
                                orderInfo.Status == 1 &&
                                <>
                                <Button component={Link} to={`/order/${orderInfo._id}`} className={classes.checkOutBtn} onClick={handleCompleteCheckout} variant="contianed" color="default" startIcon={<CheckCircleOutlineIcon />}>
                                    complete&nbsp;Order
                                        </Button>                         
                                </>                                        
                            }

                            {
                                orderInfo.Status == 2 &&               
                                <>
                                <PayPalButton
                                amount={orderInfo.Amount}
                                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                onSuccess={(details, data) => {
                                  alert("Transaction completed by " + details.payer.name.given_name);
                         
                                  // OPTIONAL: Call your server to save the transaction
                                  payOrder(orderInfo._id, data.orderID)
                                }}
                                options={{
                                    currency: 
                                    "CAD",
                                  clientId: PRODUCTION_CLIENT_ID
                                }}
                              />  
                                <Button className={classes.checkOutBtn} onClick={(e)=>{alert("Contact us after transfer")}} variant="contianed" color="default" startIcon={<CheckCircleOutlineIcon />}>
                                    E-Transfer
                                        </Button>                                 
                               <Typography style={{ fontWeight: 500 }} variant="subtitle1" >
                                * E-Transfer to iqsemedu@gmail.com        
                                </Typography>

                              </>                   
                                                            
                            }
                        </Paper>

                    </Grid>

                </Grid>
            </Container>
        )
    return (<div>loading</div>)
}
