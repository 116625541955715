/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 04:56:53
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 13:36:00
 * @Description: file content
 */
import create from 'zustand'
import Axios from 'axios'
// or for IE compatibility
// import create from 'zustand/index.cjs.js'
// const url="https://elearning-api-xctech.herokuapp.com/api""https://api-courses-iqsem.herokuapp.com/api" "http://localhost:3000"

//const baseUrl = "https://iqsemabacus.com:3000"
//const baseUrl = "http://127.0.0.1:3000"
//const url = `${baseUrl}/api`
const url = "/api"
//const authUrl = `${baseUrl}/auth`
const authUrl = "/auth"
const [useStore] = create((set, get) => ({
    count: 0,
    cartCount: 0,
    cart: [],
    notice: [],
    loginInfo: null,
    userInfo: undefined,
    loginWindowOn: false,
    registerWindowOn: false,
    forgotWindowOn: false,
    setLoginWindow: (status) => {
        set(s => ({ loginWindowOn: status }))
    },
    setRegisterWindow: (status) => {
        console.log("set register", status)
        set(s => ({ registerWindowOn: status }))
    },
    setForgotWindow: (status) => {
        console.log("send email", status)
        set(s => ({ forgotWindowOn: status }))
    },
    /**
     * 
     */
    login: async (email, password, rememberMe) => {
        const result = await Axios.post(`${authUrl}/login`, { Email: email, Password: password })
        console.log(result.data)
        if (result.status == 201 && result.data && !result.data.error) {
            console.log(result.data)
            result.data.rememberMe = rememberMe
            localStorage.setItem('login', JSON.stringify(result.data))
            console.log("zustand login info change", result.data)
            set({ loadLogin: {}})
            set({ loginInfo: result.data })
            set({ userInfo: {}})
            set({ userInfo: get().getUserInfoWithToken() })

            // get().getUserInfoWithToken()
            get().addNoticeMessage("Login success")

            return true
        } else if (result.data && result.data.error) {
            get().addNoticeMessage(result.data.message, "error")
        } else {

            get().addNoticeMessage("Login failed", "error")
        }
    },
    register: async (data) => {
        const result = await Axios.post(`${authUrl}/register`, data)
        console.log(result.data)
        if (result.status == 201 && result.data && !result.data.error) {
            // get().getUserInfoWithToken()
            get().addNoticeMessage("Register success")

            return true
        } else if (result.data && result.data.error) {
            get().addNoticeMessage(result.data.message, "error")
        } else {
            get().addNoticeMessage(result.data.message ? result.data.message : "Register failed", "error")
        }
    },
    sendPassword: async (data) => {
        console.log("data:"   + data);
        const result = await Axios.post(`${authUrl}/reset`, data)
        console.log(result.data)
        if (result.status == 200 && result.data && !result.data.error) {
            // get().getUserInfoWithToken()
            get().addNoticeMessage("Password sent")

            return true
        } else if (result.data && result.data.error) {
            get().addNoticeMessage(result.data.message, "error")
        } else {
            get().addNoticeMessage(result.data.message ? result.data.message : "Register failed", "error")
        }
    },    
    logout: () => {
        set(s => ({ loginInfo: null }))
        localStorage.removeItem('login')
        set(s =>({ userInfo: null}))
        get().addNoticeMessage("You have log out")

    },
    loadLogin: () => {
        let tempLoginInfo = JSON.parse(localStorage.getItem("login"))
        if (tempLoginInfo && tempLoginInfo.rememberMe) {
            console.log("zustand login info change", tempLoginInfo)
            set({ loginInfo: tempLoginInfo })
            set({ userInfo: get().getUserInfoWithToken() })
            return get().loginInfo

        } else {
            localStorage.removeItem('login')
        }
    },
    setUser: (user) => {
        set({ userInfo: user})
    },
    getUserInfoWithToken: async () => {

        const result = await Axios({
            method: "post",
            url: `${url}/accounts/user`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`
            },

        })
        if (result.status == 200 && result.data && !result.data.error) {

            console.log("userdata", result.data)
            set({ userInfo: {} })
            set({ userInfo: result.data })
            console.log("new userInfo", get().userInfo)
            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`cannot get user data with token`, "error")
            return
        }
    },
    //notice module

    addNoticeMessage: (message, type = "success") => {

        let newObj = { id: Date.now(), message: message, type: type }
        let noticeArray = get().notice
        noticeArray.push(newObj)
        let newArray = noticeArray.slice(0)
        set(s => ({ notice: newArray }))
        setTimeout(() => { get().removeNoticeMessage(newObj.id) }, 6000);

    },
    removeNoticeMessage: (id) => {
        console.log("remove", id)
        let noticeArray = get().notice
        //console.log(noticeArray)
        for (let i = 0; i < noticeArray.length; i++) {
            if (noticeArray[i].id == id) {
                noticeArray.splice(i, 1)
                break
            }
        }
        let newArray = noticeArray.slice(0)
        set({ notice: newArray })
    },
    getOne: async function (type, id) {
        const result = await Axios.get(`${url}/${type}/${id}`)
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return { _id: id, Status: false }
        } else {
            get().addNoticeMessage(`Cannot get ${type}`, "error")
            return { _id: id, Status: false }
        }
    },
    getOneSecure: async function (type, id) {
        const loginInfo = get().loginInfo
        if (!loginInfo) {
            get().addNoticeMessage(`could not load login info`, "error")
            return
        }

        const result = await Axios({
            method: "get",
            url: `${url}/${type}/${id}`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`
            }
        })
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return { _id: id, Status: false }
        } else {
            get().addNoticeMessage(`Cannot get ${type}`, "error")
            return { _id: id, Status: false }
        }
    },
    getAll: async function (type, perPage = 0, page = 1) {
        const result = await Axios({
            method: "get",
            url: `${url}/${type}`,
            params: {
                PerPage: perPage,
                PageNo: page
            }
        })
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot get ${type}`, "error")
            return
        }
    },
    getAllSecure: async function (type, perPage = 0, page = 1) {
        const result = await Axios({
            method: "get",
            url: `${url}/${type}`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`,
            },
            params: {
                PerPage: perPage,
                PageNo: page
            }
        })
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot get ${type}`, "error")
            return
        }
    },
    addOne: async function (type, data) {
        console.log(type + ":    " + data)
        const result = await Axios({
            method: "post",
            url: `${url}/${type}`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`
            },
            data: data
        })
        if (result.status == 201 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot get ${type}`, "error")
            return
        }
    },
    updateOne: async function (type, data) {
        const result = await Axios({
            method: "put",
            url: `${url}/${type}`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`
            },
            data: data
        })
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot update ${type}`, "error")
            return
        }
    },
    deleteOne: async function (type, data) {
        console.log("userinfo:    " + data)
        const result = await Axios({
            method: "delete",
            url: `${url}/${type}`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`
            },
            data: data
        })
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot get ${type}`, "error")
            return
        }
    },
    createShippingAddress: async function (accountId, data) {
        const ShippingAddress = await get().addOne('shippingaddress', data)
        console.log("ShippingAddress:    " + ShippingAddress)
        if (ShippingAddress) {
            const result = await Axios({
                method: "post",
                url: `${url}/accounts/updateShippingAddress`,
                headers: {
                    authorization: `Bearer ${get().loginInfo.token}`
                },
                data: { AccountId: accountId, ShippingAddressId: [ShippingAddress._id] }
            })
            if (result.status == 200 && result.data && !result.data.error) {

                return result.data
            } else if (result.data && result.data.error) {

                get().addNoticeMessage(result.data.message, "error")
                return
            } else {
                get().addNoticeMessage(`Cannot create address`, "error")
                return
            }
        }
    },
    deleteShippingAddress: async function (accountId, id) {
        await get().deleteOne('shippingaddress', { _id: id })

        const result = await Axios({
            method: "delete",
            url: `${url}/accounts/updateShippingAddress`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`
            },
            data: { AccountId: accountId, ShippingAddressId: [id] }
        })
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot delete address`, "error")
            return
        }
    },
    createPost: async function (blogId, data) {
        const Post = await get().addOne('posts', data)
        console.log("Post:    " + Post)
        if (Post) {
            const result = await Axios({
                method: "post",
                url: `${url}/blogs/updatePost`,
                data: { BlogId: blogId, PostId: [Post._id] }
            })
            if (result.status == 200 && result.data && !result.data.error) {

                return result.data
            } else if (result.data && result.data.error) {

                get().addNoticeMessage(result.data.message, "error")
                return
            } else {
                get().addNoticeMessage(`Cannot create post`, "error")
                return
            }
        }
    },
    deletePost: async function (blogId, id) {
        await get().deleteOne('posts', { _id: id })
        const result = await Axios({
            method: "delete",
            url: `${url}/blogs/updatePost`,
            data: { BlogId: blogId, PostId: [id] }
        })
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot delete post`, "error")
            return
        }
    },
     createLesson: async function (productId, data) {
         const Lesson = await get().addOne('lessons', data)
         console.log("Lesson:    "+ Lesson)
         if (Lesson) {
             const result = await Axios({
                 method:"post", 
                 url: `${url}/products/updateLesson`,
                 data: {ProductId: productId, LessonId: [Lesson._id]}
             })
             if (result.status == 200 && result.data && !result.data.error) {

                 return result.data
             } else if (result.data && result.data.error) {

                 get().addNoticeMessage(result.data.message, "error")
                 return
             } else {
                 get().addNoticeMessage(`Cannot create lesson`, "error")
                 return 
             }           
         }
     },
     deleteLesson: async function (productId, id) {
         await get().deleteOne('lessons', {_id: id})
         const result = await Axios({
             method:"delete", 
             url: `${url}/products/updateLesson`,
             data: {ProductId: productId, LessonId: [id]}
         })
         if (result.status == 200 && result.data && !result.data.error) {

             return result.data
         } else if (result.data && result.data.error) {

             get().addNoticeMessage(result.data.message, "error")
             return
         } else {
             get().addNoticeMessage(`Cannot delete lesson`, "error")
             return 
         }           
     },                
    search: async function (type, query, limit = 8, page = 1, filter) {
        let filterObj = {}
        for (let key in filter) {
            filterObj[`filter[${key}]`] = filter[key]
        }
        const result = await Axios(
            {
                method: 'get',
                url: `${url}/${type}/search`,
                params: {
                    query: query,
                    limit: limit,
                    page: page,
                    ...filterObj
                }
            })
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot get ${type}`, "error")
            return
        }
    },
    searchSecure: async function (type, query, limit = 8, page = 1, filter) {
        let filterObj = {}
        for (let key in filter) {
            filterObj[`filter[${key}]`] = filter[key]
        }
        console.log(filterObj)
        const result = await Axios(
            {
                method: 'get',
                url: `${url}/${type}/search`,
                headers: {
                    authorization: `Bearer ${get().loginInfo.token}`
                },
                params: {
                    query: query,
                    limit: limit,
                    page: page,
                    ...filterObj
                }
            })
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot get ${type}`, "error")
            return
        }
    },

    /**
     * 
     * @param {string} id 
     * @param {number} count add count to the current count
     * @param {number} type 1=regular 2=course, count of course will not be more than 1
     * @param {bool} remove remove the current count
     */
    upsertCart: function (id, type = 1, count = 1, remove = false) {
        console.log("product uid", id)
        console.log("product count", count)
        if (count < -1) {
            get().addNoticeMessage(`Something went wrong, item not added to cart`, "error")
            return
        }
        let cartObject = localStorage.getItem("cart")
        if (!cartObject) {
            cartObject = []
        } else {
            cartObject = JSON.parse(cartObject)
        }
        let update = false
        console.log("starting object", JSON.stringify(cartObject))
        let changedCartObject = cartObject.map((v) => {
            if (v._id == id) {
                if (remove) {
                    update = true
                    return undefined
                } else {
                    update = true
                    if (type == 2) {
                        v.count = 1
                    } else {
                        const newValue = parseInt(v.count) + count
                        if (newValue >= 1) {
                            v.count = newValue
                        }

                    }
                    return v
                }
            }
            return v
        })
        //insert the item if not found
        if (!update) {

            changedCartObject.push({ _id: id, count: count })
        }
        //remove undefine or null items
        const result = changedCartObject.filter(v => { if (v) { return v } })
        //store updated cart to zustand
        set({ cartCount: result.length, cart: result })
        //store updated cart to localstorage
        localStorage.setItem("cart", JSON.stringify(result))
        if (remove) {
            get().addNoticeMessage(` Item has been deleted from cart`)
            return
        }
        if (type == 1) {
            if (count > 0) {
                get().addNoticeMessage(` ${count} item(s) has been added to cart`)
            }
            if (count < 0) {
                get().addNoticeMessage(` ${-count} item(s) has been removed from cart`)
            }
        } else if (type == 2) {
            get().addNoticeMessage(`Course has been added to cart`)
        }

    },
    cleanCart: function () {
        localStorage.removeItem("cart")
        set(s => ({ cart: [] }))
    },
    getCart: function () {
        return JSON.parse(localStorage.getItem("cart"))
    },
    //load cart information from localstorage to zustand
    loadCartCount: function () {
        if (JSON.parse(localStorage.getItem("cart"))) {
            set({ cartCount: JSON.parse(localStorage.getItem("cart")).length, cart: JSON.parse(localStorage.getItem("cart")) })
        } else {
            localStorage.setItem("cart", "[]")
            set({ cartCount: 0, cart: [] })
        }

    },
    checkOut: async (cartInfo) => {

        const loginInfo = get().loginInfo
        if (!loginInfo) {
            get().addNoticeMessage(`could not load login info`, "error")
            return
        }
        //tranfor count to quantity

        const result = await Axios({
            method: "post",
            url: `${url}/orders/checkout`,
            headers: {
                authorization: `Bearer ${loginInfo.token}`
            },
            data: { products: cartInfo }
        })
        if (result.status == 200 && result.data && !result.data.error) {
            localStorage.removeItem("cart")
            set(s => ({ cart: [] }))
            set(s => ({ cartCount: 0 }))
            get().addNoticeMessage("Order Created, please fill in required information")
            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot check out`, "error")
            return
        }
    },
    returnOrder: async function (id) {
        const result = await Axios({
            method: "post",
            url: `${url}/orders/return`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`
            },
            data: {
                _id: id,
                "Shipping.ReturnedDate": new Date()
            }
        })
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return 
        } else {
            get().addNoticeMessage("check fail", "error")
            return 
        }
    },   
    deliverOrder: async function (id) {
        const result = await Axios({
            method: "post",
            url: `${url}/orders/deliver`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`
            },
            data: {
                _id: id,
                "Shipping.DeliveredDate": new Date()
            }
        })
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return 
        } else {
            get().addNoticeMessage("check fail", "error")
            return 
        }
    },                 
    checkPurchased: async function (id) {
        const result = await Axios({
            method: "post",
            url: `${url}/orders/checkPurchased`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`
            },
            data: {id: id}
        })
        if (result.status == 200 && result.data && !result.data.error) {

            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return 
        } else {
            get().addNoticeMessage("check fail", "error")
            return 
        }
    },                
    shipOrder: async (orderId, shippingInfo) => {

        const result = await Axios({
            method: "post",
            url: `${url}/orders/ship`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`
            },
            data: {
                _id: orderId,
                "Shipping.TrackingNumber": shippingInfo.TrackingNumber, 
                "Shipping.Shipper": shippingInfo.Shipper,
                "Shipping.ShippedDate": new Date()
            }
        })
        if (result.status == 200 && result.data && !result.data.error) {

            get().addNoticeMessage("Order Marked as Shipped")
            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot Marked as Shipped`, "error")
            return
        }
    },
    payOrder: async (id, orderId) => {
        //tranfor count to quantity

        const result = await Axios({
            method: "post",
            url: `${url}/orders/pay`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`
            },
            data: {
                _id: id, OrderID: orderId 
            }
        })
        if (result.status == 200 && result.data && !result.data.error) {

            get().addNoticeMessage("Order Marked as Paid")
            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot complete order`, "error")
            return
        }
    },
    completeOrder: async (orderInfo, address) => {
        let courseOnly = true;
        for (let product of orderInfo.Product) {
            if (product.Type == 1) courseOnly = false
        }
        if (!address && !courseOnly) {
            get().addNoticeMessage(`Please select an address`, "error")
            return
        }

        //tranfor count to quantity

        const result = await Axios({
            method: "post",
            url: `${url}/orders/complete`,
            headers: {
                authorization: `Bearer ${get().loginInfo.token}`
            },
            data: {
                _id: orderInfo._id,
                Tax: orderInfo.Tax,
                Amount: orderInfo.Amount,
                ShippingCharge: orderInfo.ShippingCharge,
                Shipping: {
                    ShippingAddress: address
                }
            }
        })
        if (result.status == 200 && result.data && !result.data.error) {

            get().addNoticeMessage("Order Completed")
            return result.data
        } else if (result.data && result.data.error) {

            get().addNoticeMessage(result.data.message, "error")
            return
        } else {
            get().addNoticeMessage(`Cannot complete order`, "error")
            return
        }
    },
    reset: () => set({ count: 0 })
}))

export { useStore }
