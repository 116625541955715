/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 04:42:06
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-21 15:52:39
 * @Description: file content
 */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import React from 'react';
import { useStore } from "../global/zustand";
import AddressManager from "../widget/Address/AddressManager";

const useStyles = makeStyles({
    // table: {
    //     minWidth: 650,
    // },
    title: {
        fontSize: "2rem",
        fontWeight: 55
    },
    createBtn: {
        height: "4rem",
        display: "flex",
        justifyContent: "flex-end"
    },
    headerContianer: {
        marginTop: "2rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    editFrom: {
        marginTop: "2rem",
        minWidth: "480px",
        minHeight: "400px",
        width: "100%",
        padding: "1.5rem 2rem 1.5rem 2rem"
    },
    
    textBox: {
        marginBottom: "24px"
    },
    changeImageBtn: {
        margin: "1rem"
    }
});
export default function UserProfile(props) {
    const classes = useStyles()
    const userInfo = useStore(s=>s.userInfo)
    const setUser = useStore(s=>s.setUser)    
    const updateOne = useStore(s => s.updateOne)

    const handleChange = (event) => { 
        event.persist();
        setUser({...userInfo, [event.target.name]: event.target.value });  
    };

    const handleSaveUser = async () => {
        console.log("user:    "+userInfo);
        const result = updateOne('accounts', userInfo)
        if (result) {

        }
    };    

    if (userInfo) {
        return (
            <Container>

                <Container>
                    <Grid spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center" container>
                        <Grid item xs={12} sm={10} md={9} lg={8}>

                            {/* <Paper className={classes.editFrom} elevation={6}> */}
                            <Box className={classes.headerContianer}>
                              
                                    <Typography className={classes.title}>
                                       Edit Profile
                        </Typography>

                            </Box>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        onChange={handleChange}
                                        name="Email"
                                        label="Email"
                                        variant="outlined"
                                        // defaultValue={props.option == "edit" ? "Email" : ""}
                                        value={userInfo.Email}

                                    />

                                </Grid>

                                <Grid item xs={12}>

                                    <TextField
                                        className={classes.textBox}
                                        fullWidth
                                        onChange={handleChange}
                                        name="Firstname"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        defaultValue={userInfo.Firstname}
                                        value={userInfo.Firstname || ""}
                                        label="First Name"
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textBox}
                                        fullWidth
                                        onChange={handleChange}
                                        name="Lastname"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        defaultValue={userInfo.Lastname}
                                        value={userInfo.Lastname || ""}
                                        label="Last Name"
                                        variant="outlined"
                                    />
                                  <TextField
                                        className={classes.textBox}
                                        fullWidth
                                        onChange={handleChange}
                                        name="Phonenumber"

                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        defaultValue={userInfo.Phonenumber}
                                        value={userInfo.Phonenumber || ""}
                                        label="Phone Number"
                                        variant="outlined"
                                    />                                                     
                                    <TextField
                                        className={classes.textBox}
                                        fullWidth
                                        onChangeCapture={handleChange}
                                        name="Password"                            
                                        // defaultValue={props.option == "edit" ? "ID" : ""}
                                        value={userInfo.Password}
                                        type="password"
                                        userInfo
                                        label="Passowrd"
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textBox}
                                        fullWidth
                                        onChangeCapture={handleChange}
                                        name="Passcode"
                                        // defaultValue={props.option == "edit" ? "ID" : ""}
                                        value={userInfo.Passcode}
                                        label="Passcode"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                              
                                <Grid
                                    className={classes.createBtn}
                                    item xs={12}>
                                 
                                        <Button onClick={handleSaveUser} color="primary" >Save Your Changes</Button>
                                   
                                   
                                </Grid>
                                
                            </Grid>
                            <AddressManager accountId={userInfo._id}/>
                            {/* </Paper> */}
                        </Grid>

                    </Grid>
                  
                </Container>
            </Container>
        )
    
    } 
    else {
        return (
        <div>Loading...</div>
        )
    }
}
