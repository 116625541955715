/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 09:49:40
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 13:59:51
 * @Description: file content
 */
import { IconButton, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React from 'react';
import {
    Link
} from "react-router-dom";
import { useStore } from "../global/zustand";
// import classes from '*.module.css';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function AlertDialogSlide() {
    const [open, setOpen] = React.useState(false);
    const setRegisterWindow = useStore(s => s.setRegisterWindow)
    const setLoginWindow = useStore(s => s.setLoginWindow)
    const setForgotWindow = useStore(s => s.setForgotWindow)
    const loginWindowOn = useStore(s => s.loginWindowOn)
    const login = useStore(s => s.login)

    const handleClose = () => {
        setOpen(false);
    };
    const [state, setState] = React.useState({
        email: "",
        remenberMe: true,
        password: "",
        checkedG: true,
    });
    // React.useEffect(() => {
    //     console.log("change")
    // }, [ loginWindowOn])

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const handleTextChange = (event) => {
        event.persist();
        console.log({ [event.target.name]: event.target.value })
        setState({ ...state, [event.target.name]: event.target.value });
    };
    return (
        <div style={{ display: "inline-block" }}>

            <Tooltip title="Login">
                <IconButton size="medium"><AccountCircleIcon fontSize="large" color="primary" onClick={() => { setLoginWindow(true) }}></AccountCircleIcon></IconButton>
            </Tooltip>

            <Dialog
                fullWidth
                maxWidth={"xs"}
                open={loginWindowOn}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Login"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="email"
                            value={state.email}
                            onChange={handleTextChange}
                            id="standard-required"
                            label="Username"
                            defaultValue=""
                        />

                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description">
                        <TextField
                            fullWidth
                            onChange={handleTextChange}
                            value={state.password}
                            name="password"
                            variant="outlined"
                            id="standard-password-input"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                        />
                    </DialogContentText>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.rememberMe}
                                onChange={handleChange}
                                name="rememberMe"
                                color="primary"
                            />
                        }
                        label="Remember me"
                    />
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography >
                            Don't have an account? Register <Link onClick={() => {
                                setLoginWindow(false)
                                setRegisterWindow(true)
                            }}
                            >Here
                                </Link>
                        </Typography>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography >
                            Forgot password? Request password <Link onClick={() => {
                                setLoginWindow(false)
                                setForgotWindow(true)
                            }}
                            >Here
                                </Link>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button onClick={() => { setLoginWindow(false) }} color="primary">
                        Close
          </Button>
                    <Button onClick={
                        async () => {
                            const loginResult = await login(state.email, state.password, state.remenberMe)
                            if (loginResult) setLoginWindow(false)
                        }} color="primary">
                        Login
          </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}
