/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-21 05:25:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-27 17:06:53
 * @Description: file content
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useStore } from "../../../global/zustand";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateLesson(props) {
    const [open, setOpen] = React.useState(false);
    const createLesson = useStore(s=>s.createLesson)
    const [lesson, setLesson] = React.useState();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleChange = (event) => {
        event.persist();
        setLesson({...lesson, [event.target.name]: event.target.value });     
    };

    const handleCreate = async (e) => {
        e.preventDefault()
        console.log("Create lesson", lesson)
        await createLesson(props.productId, lesson)
        props.loadProduct()
        setOpen(false);
    }          

    return (
        <div>
            <Button color="primary" onClick={handleClickOpen}>
                Create Lesson
            </Button>
          

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Create Lesson"}</DialogTitle>
                <DialogContent>
                    <TextField
                        style={{ marginBottom: "1rem" }}
                        required
                        fullWidth
                        label="Title"
                        name="Title"
                        value={(lesson)?lesson.Title:""}
                        variant="outlined"
                        onChange={handleChange}
                    />
                    <TextField
                        style={{ marginBottom: "1rem" }}
                        required
                        fullWidth
                        label="Youtube Video ID"
                        name="VideoID"
                        value={(lesson)?lesson.VideoID:""}
                        variant="outlined"
                        onChange={handleChange}                        
                    />
                    <TextField
                        style={{ marginBottom: "1rem" }}
                        required
                        select
                        fullWidth
                        label="Preview"
                        name="Preview"
                        value={(lesson)?lesson.Preview:""}
                        variant="outlined"
                        onChange={handleChange}                        
                    >
                    <MenuItem value={true}>
                    Yes
                    </MenuItem>
                    <MenuItem value={false}>
                    No
                    </MenuItem>
                    </TextField>

                    <TextField
                        style={{ marginBottom: "1rem" }}
                        required
                        fullWidth
                        multiline
                        rows={2}
                        label="Description"
                        name="Description"
                        value={(lesson)?lesson.Description:""}
                        onChange={handleChange}
                        variant="outlined"
                    />
                   

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreate} color="primary">
                        Create
          </Button>
                    <Button onClick={handleClose} color="primary">
                        Close
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}