/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-21 05:25:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-21 09:52:00
 * @Description: file content
 */
import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Rating from '@material-ui/lab/Rating';
import React from 'react';
import { useStore } from "../../global/zustand";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreatePost(props) {
    const {loadBlogs, blogId} = props;    
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState({});
    const createPost = useStore(s=>s.createPost)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleChange = (event) => {
        event.persist();
        setValue({...value, [event.target.name]: event.target.value });       
    };        
    const handleCreate = (e) => {
        e.preventDefault()
        createPost(blogId, value);
        loadBlogs();
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button color="primary" onClick={handleClickOpen}>
                Create Post
            </Button>
          

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Create Blog"}</DialogTitle>
                <DialogContent>
                    <TextField
                        style={{ marginBottom: "2rem" }}
                        required
                        fullWidth
                        label="Title"
                        name="Title"
                        value={value.Title}
                        onChange={handleChange}                        
                        variant="outlined"
                    />
                    <TextField
                        style={{ marginBottom: "2rem" }}
                        required
                        fullWidth
                        label="Poster"
                        name="Poster"
                        value={value.Poster}
                        onChange={handleChange}                        
                        variant="outlined"
                    />

                    <TextField
                        style={{ marginBottom: "2rem" }}
                        required
                        fullWidth
                        multiline
                        rows={2}
                        label="Body"
                        name="Body"
                        value={value.Body}
                        onChange={handleChange}                        
                        variant="outlined"
                    />
                    <Box style={{marginLeft:"1rem"}}>
                        <Typography style={{ fontWeight: 500, marginBottom: "1rem" }} >
                            Rating:
                    </Typography>
                        <Rating
                            name="Rating"
                            value={value.Rating}
                            onChange={handleChange}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreate} color="primary">
                        Create
          </Button>
                    <Button onClick={handleClose} color="primary">
                        Close
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}