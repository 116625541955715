import Box from "@material-ui/core/Box";
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RemoveIcon from '@material-ui/icons/Remove';
import React from 'react';
import {
    Link
} from "react-router-dom";
import { useStore } from '../../global/zustand';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minWidth: "140px",
        marginTop: "1.2rem",
        paddingBottom: "1.5rem",
        [theme.breakpoints.down('sm')]:{
            display:"block",
            minWidth: "140px",
        }
        // borderBottom: "1px solid #989898"
    },
    rootLast: {
        display: 'flex',
        minWidth: "140px",
        marginTop: "1.2rem",
        paddingBottom: "1.5rem",
        [theme.breakpoints.down('sm')]:{
            display:"block",
            minWidth: "140px",
        }

    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 250,
        [theme.breakpoints.down('sm')]:{
            width:0
        }
    },
    controls: {
        display: 'flex',
        flexDirection: "row-reverse",
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    stockIndicator: {
        borderRadius: "6px",
        color: "white",
        fontWeight: 500,
        display: "inline",
        fontSize: "1rem",
        padding: "0.2rem 0.4rem 0.2rem 0.4rem"
    },
}));

export default function MediaControlCard(props) {
    const classes = useStyles();
    const upsertCart = useStore(s => s.upsertCart)
    const stockIndicator = () => {
        let color = ""
        let text = ""
        if (props.productInfo.Type == 2) {
            color = "#F44336"
            text = "Unlimited"
        } else if (props.productInfo.Quantity) {
            if (props.productInfo.Quantity > 10) {
                color = "#617B30"
                text = "In Stock"

            } else if (props.productInfo.Quantity > 0) {
                color = "#FFD11D"
                text = "Low"
            } else {
                color = "#810020"
                text = "Temporary Out of Stock"
            }
        } else {
            color = "#767676"
            text = "No Stock Info"
        }
        return <Box style={{ backgroundColor: color, fontWeight: 500 }} className={classes.stockIndicator}>{text}</Box>
    }
    if (props.productInfo.Name) {
        return (
            <div elevation={5} className={props.last ? classes.rootLast : classes.root}>
                <CardMedia
                // style={{minWidth:"250px"}}
                    className={classes.cover}
                    image={props.productInfo.ImageUrl}
                    title="Live from space album cover"
                />
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            <Typography component="h5" variant="h5">
                                {props.productInfo.Name}
                            </Typography>
                            {/* <Typography style={{ fontWeight: 500, textDecoration: "none" }} component={Link} to={`/product/${props.productInfo._id}`}>
                                ({props.productInfo._id})
                            </Typography> */}
                        </Box>

                        <Typography style={{ fontWeight: 500 }} variant="subtitle1" color="textSecondary">
                            {props.productInfo.Type == 1 && "Textbook & Tools"}
                            {props.productInfo.Type == 2 && "Online Course"}
                        </Typography>
                        <Typography style={{ fontWeight: 500 }} variant="subtitle1" color="textSecondary">
                            Unit Price: ${props.productInfo.UnitPrice}
                        </Typography>
                        <Typography style={{ fontWeight: 500 }} variant="subtitle1" color="textSecondary">
                            Stock: {stockIndicator()}
                        </Typography>

                    </CardContent>
                    <div className={classes.controls}>

                        <IconButton aria-label="play/pause">
                            <DeleteForeverIcon onClick={() => { upsertCart(props.productInfo._id, props.productInfo.Type, 0, true) }} />
                        </IconButton>

                        {props.productInfo.Type == 1 &&
                            <>  <IconButton aria-label="play/pause">
                                <AddIcon onClick={() => {
                                    upsertCart(props.productInfo._id, props.productInfo.Type, 1)
                                }} />
                            </IconButton>
                                <Typography variant="subtitle1" color="textSecondary">
                                    Quantity: {props.productInfo.count}
                                </Typography>
                                <IconButton aria-label="play/pause">
                                    <RemoveIcon onClick={() => {
                                        if (props.productInfo.count > 1) {
                                            upsertCart(props.productInfo._id, props.productInfo.Type, -1)
                                        }

                                    }} />
                                </IconButton></>}


                    </div>
                </div>

            </div>
        );
    } else {
        return (<div elevation={5} className={classes.root}>
            <Typography variant="h5">Item id: {props.productInfo._id} has been deleted.</Typography>

            <IconButton aria-label="play/pause">
                <DeleteForeverIcon onClick={() => { upsertCart(props.productInfo._id, props.productInfo.Type, 0, true) }} />
            </IconButton>
        </div>)
    }

}
