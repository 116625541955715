/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-07 05:42:25
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-21 09:51:33
 * @Description: file content
 */
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination from '@material-ui/lab/Pagination';
import Rating from '@material-ui/lab/Rating';
import 'moment-timezone';
import React from 'react';
import { useStore } from "../../global/zustand";
import CreateBlog from "./CreatePost";
import EditBlog from "./EditBlog";



const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        margin: "2rem 0 1rem 2rem",
        fontSize: "2rem",
        fontWeight: 55

    },
    createBtn: {
        height: "36px"
    },
    headerContianer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    statusBox: {
        color: "white",
        background: "#E63018",
        fontWeight: 500,
        display: "inline-block",

        padding: "0.2rem 0.5rem",
        borderRadius: "5px"
    },
    checkOutBtn: {
        marginTop: "2rem",
        background: 'linear-gradient(45deg, #00A08F 30%, #124653 90%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(75, 122, 82, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    },
    PaidBtn: {
        marginTop: "2rem",
        background: 'linear-gradient(45deg, #FFCF40 10%, #BF9B30 70%),linear-gradient(135deg, #4B7F52 30%, #00A08F 90%)',
        // borderRadius: 3,
        // border: 0,
        color: 'white',
        // // height: 48,
        padding: '0.5rem 1rem',
        boxShadow: '0 3px 5px 2px rgba(255,207,64, .3)',
        marginRight: "1rem",
        marginBottom: "1rem"
    }
});
export default function Index() {
    const classes = useStyles()
    const getAll = useStore(s => s.getAll)
    const [page, setPage] = React.useState(1)
    const [blogs, setBlogs] = React.useState()
    const loadBlogs = async () => {
        const result = await getAll('blogs')
        if (result) {
            console.log("blogs:    " + result[0]._id);
            setBlogs(result)
            setBlogs({...result, totalPage: Math.ceil(result[0].Post.length/3)})
        }
    }
    React.useEffect(()=>{loadBlogs()}, [page])

    if (blogs) {
    return (
        <Container>
            <Box className={classes.headerContianer}>
                <Typography className={classes.title}>
                    Review Management
                </Typography>

                <CreateBlog
                    loadBlogs={loadBlogs}
                    blogId={blogs[0].id}
                />
            </Box>

            <Table style={{ overflowX: 'auto' }}>

                <TableHead>
                    <TableRow >

                        <TableCell style={{ fontWeight: 450, fontSize: "1.1rem" }}>
                            Title:
                            </TableCell>
                        <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                            Body:
                            </TableCell>
                        <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                            Rating:
                            </TableCell>

                        <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>
                            Poster:
                            </TableCell>
                        <TableCell style={{ fontWeight: 400, fontSize: "1.1rem" }}>

                        </TableCell>


                    </TableRow>
                </TableHead>
                <TableBody>
                    {blogs[0] && blogs[0].Post.slice(page*3-3, page*3).map((v) => {
                        return (
                            <Row key={v._id} value={v}  loadBlogs={loadBlogs} blogId={blogs[0]._id}/>
                        )
                    })}


                </TableBody>

            </Table>
            <Box style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                <Pagination page={page} onChange={(e, v) => { setPage(v) }} count={blogs.totalPage} variant="outlined" shape="rounded" />
            </Box>


        </Container>
    )
    }
    return (
        <div>
            <button onClick={loadBlogs}>reload</button>

        </div>
    )
}
function Row(props) {
    const deletePost = useStore(s=>s.deletePost)
    
    const handleDelete = async (e) => {
        e.preventDefault()
        const result = await deletePost(props.blogId, props.value._id)
        if(result){
            props.loadBlogs()
        }    
    }        
    return (
        <>
            <TableRow>

                <TableCell style={{ fontWeight: 500 }}>
                    {props.value.Title}
                            </TableCell>
                <TableCell style={{ fontWeight: 500 }}>
                    {props.value.Body}
                            </TableCell>
                <TableCell>
                    <Rating name="read-only" value={props.value.Rating} readOnly />

                </TableCell>
                <TableCell style={{ fontWeight: 500 }}>
                    {props.value.Poster}
                </TableCell>
                <TableCell style={{ fontWeight: 500 }}>
                <EditBlog 
                    loadBlogs={props.loadBlogs}
                    newPost={props.value}                    
                />
                    <Tooltip title="Delete Post">
                        <IconButton onClick={handleDelete}  >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>

            </TableRow>

        </>
    )
}
