/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 04:42:06
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-21 15:37:10
 * @Description: file content
 */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import React from 'react';
import {
    useParams
} from "react-router-dom";
import { useStore } from "../../global/zustand";
import EnglishPic from "../../images/english-800x600.jpg";
import AddressManager from "../../widget/Address/AddressManager";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles({
    // table: {
    //     minWidth: 650,
    // },
    title: {
        margin: "1rem 0 1rem 0rem",
        fontSize: "2rem",
        fontWeight: 55

    },
    createBtn: {
        height: "4rem",
        display: "flex",
        justifyContent: "flex-end"
    },
    headerContianer: {
        marginTop: "2rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    editFrom: {
        marginTop: "2rem",
        minWidth: "480px",
        minHeight: "400px",
        width: "100%",
        padding: "1.5rem 2rem 1.5rem 2rem"
    },
    courseImage: {
        background: `linear-gradient( 165deg,rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0) 40% ),url(${EnglishPic})`,
        // width: "100%",
        height: "100%",
        minHeight: "250px",
        backgroundPosition: "center",
        backgroundSize: "cover",


    },
    textBox: {
        marginBottom: "24px"
    },
    changeImageBtn: {
        margin: "1rem"
    }
});
export default function EditUser(props) {
    const classes = useStyles();
    const userInfo = useStore(s=>s.userInfo)
    const { id } = useParams();
    const getOne = useStore(s => s.getOneSecure)
    const updateOne = useStore(s => s.updateOne)
    const addOne = useStore(s => s.addOne)
    const [account, setAccount] = React.useState({})
    const history = useHistory()

    const handleChange = (event) => {
        event.persist();
        setAccount({...account, [event.target.name]: event.target.value });     
    };
    const handleSaveUser = async (e) => {
        e.preventDefault()
        console.log("Updated account", account)
        await updateOne('accounts', account)
        history.push('/usermanagement')
    }    
    const handleCreateUser = async (e) => {
        e.preventDefault()
        console.log("created account", account)
        await addOne('accounts', account)
        history.push('/usermanagement')
    }        
    const loadAccount = async () => {
        console.log("id:    " + id)
        const result = await getOne('accounts', id)
        if (result) {
            console.log(result)
            setAccount(result)
        }
    }        
    React.useEffect(() => {
        if (props.option == "edit") {
            loadAccount()
        }
    }, [id])
    if (account) {
        return (
            <Container>
                <Container>
                    <Grid spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center" container>
                        <Grid item xs={12} sm={10} md={9} lg={8}>

                            {/* <Paper className={classes.editFrom} elevation={6}> */}
                            <Box className={classes.headerContianer}>
                                {props.option == "edit" &&
                                    <Typography className={classes.title}>
                                        Details of User
                        </Typography>
                                }
                                {props.option == "create" &&
                                    <Typography className={classes.title}>
                                        Create a New User
                        </Typography>
                                }

                            </Box>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        onChange={handleChange}
                                        name="Email"
                                        label="Email"
                                        variant="outlined"
                                        // defaultValue={props.option == "edit" ? "Email" : ""}
                                        value={
                                            account.Email === null ||
                                            account.Email === undefined
                                            ? ""
                                            : account.Email}

                                    />

                                </Grid>

                                <Grid item xs={12}>

                                    <TextField
                                        className={classes.textBox}
                                        fullWidth
                                        onChange={handleChange}
                                        name="Firstname"

                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        // defaultValue={props.option == "edit" ? userInfo.FirstName : ""}
                                        value={
                                            account.Firstname === null ||
                                            account.Firstname === undefined
                                            ? ""
                                            : account.Firstname}
                                        label="First Name"
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textBox}
                                        fullWidth
                                        onChange={handleChange}
                                        name="Lastname"

                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        // defaultValue={props.option == "edit" ? userInfo.FirstName : ""}
                                        value={
                                            account.Lastname === null ||
                                            account.Lastname === undefined
                                            ? ""
                                            : account.Lastname}
                                        label="Last Name"
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textBox}
                                        fullWidth
                                        onChangeCapture={handleChange}
                                        name="Password"
                                        // defaultValue={props.option == "edit" ? "ID" : ""}
                                        value={
                                            account.Password === null ||
                                            account.Password === undefined
                                            ? ""
                                            : account.Password}
                                        type="password"
                                        userInfo
                                        label="Passowrd"
                                        variant="outlined"
                                    />
                                   <TextField
                                        className={classes.textBox}
                                        fullWidth
                                        onChange={handleChange}
                                        name="Phonenumber"

                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        // defaultValue={props.option == "edit" ? userInfo.FirstName : ""}
                                        value={
                                            account.Phonenumber === null ||
                                            account.Phonenumber === undefined
                                            ? ""
                                            : account.Phonenumber}
                                        label="Phone Number"
                                        variant="outlined"
                                    />                                              
                                    <TextField
                                        className={classes.textBox}
                                        fullWidth
                                        onChange={handleChange}
                                        name="Passcode"

                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        // defaultValue={props.option == "edit" ? userInfo.FirstName : ""}
                                        value={
                                            account.Passcode === null ||
                                            account.Passcode === undefined
                                            ? "0000"
                                            : account.Passcode}
                                        label="Passcode"
                                        variant="outlined"
                                    />                                    
                                    {userInfo.Role ==1 &&
                                    <TextField
                                        className={classes.textBox}
                                        fullWidth
                                        onChange={handleChange}
                                        name="Role"
                                        select

                                        // defaultValue={props.option == "edit" ? "ID" : ""}
                                        value = {
                                            account.Role === null ||
                                            account.Role === undefined
                                            ? 3
                                            : account.Role}
                                        label="Role"
                                    >
                                        <MenuItem  value={3}>
                                            Customer
                                        </MenuItem>
                                        <MenuItem  value={2}>
                                            Staff
                                        </MenuItem>                             
                                    </TextField>
                                    }
                                </Grid>
                              
                                <Grid
                                    className={classes.createBtn}
                                    item xs={12}>
                                    {props.option == "edit" &&
                                        <Button onClick={handleSaveUser} color="primary" >Save Your Changes</Button>
                                    }
                                    {props.option == "create" &&
                                        <Button onClick={handleCreateUser} color="primary" >Create</Button>
                                    }
                                </Grid>
                            </Grid>
                            <AddressManager accountId={id}/>
                            {/* </Paper> */}
                        </Grid>

                    </Grid>
                </Container>
            </Container>
        )
    }
    else {
        return (
            <div>
                Loading...
            </div>
        )
    }
}
