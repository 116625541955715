/*
 * @Author: Dongchen Xie
 * @Date: 2020-09-21 05:25:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-21 05:32:53
 * @Description: file content
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Tooltip from "@material-ui/core/Tooltip";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import React from 'react';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideoDialog(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Tooltip title="Preview Lession">
                <IconButton aria-label="delete" onClick={handleClickOpen}>
                    <PlayCircleFilledIcon />
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{props.v.Title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {props.v.Description}
          </DialogContentText>
                    <iframe style={{ border: "none" }}
                        allowFullScreen="allowFullScreen"
                        mozallowfullscreen="mozallowfullscreen"
                        msallowfullscreen="msallowfullscreen"
                        oallowfullscreen="oallowfullscreen"
                        webkitallowfullscreen="webkitallowfullscreen" width="100%" height="360" src={`https://www.youtube.com/embed/${props.v.VideoID}`}>
                    </iframe>
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleClose} color="primary">
                        Close
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}