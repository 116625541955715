/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-16 07:10:33
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-09-28 13:12:30
 * @Description: file content
 */
import { TextField, Typography } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Buttom from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { useStore } from "../global/zustand";
import banner from "../images/pexels-pixabay-159711.jpg";
import CourseCard from "../widget/courseCard";



const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: 10,
        '&': {
            marginTop: "-10",
            height: 5,
        },
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: "#F44336",


        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const StyledTab = withStyles((theme) => ({

    root: {

        textTransform: 'none',
        color: '#767676',
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: "1.5rem",
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            opacity: 1,
        },
        '&$selected': {
            color: "#F44336",
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: "#F44336",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "1rem"
        }
    },
   
    selected: {},
}))((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles((theme)=>({
    bannerImage: {
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2) ),url(${banner})`,
        width: "100%",
        height: "500px",
        backgroundPosition: "center",
        backgroundSize: "cover"
    },
    bannerTitle: {
        paddingTop: "5rem",
        color: "white",
        fontSize: "3rem",
        fontWeight: 600
    },
    bannerContainer: {
        textAlign: "center"
    },
    bannerButton: {
        marginTop: "8rem",
        width: "200px",
        border: "3px solid white",
        display: "inline-block",
        color: "white",
        fontWeight: 400,
        padding: "0.5rem 0 0.5rem 0",
        transition: "0.5s",
        '&:hover': {
            background: "linear-gradient( rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9) )",
            color: "#343434",
            border: "none",
            width: "230px",
            height: "58px"
        }
    },
    bannerButtonText: {
        fontSize: "1.5rem",
    },
    recoCourseText: {
        fontWeight: 500,
        color: "#343434"
    },
    recoCoursesContianer: {
        position: "relative",
        width: "100%",

    },
    recoCoursesPaper: {
        position: "absolute",
        top: "-2rem",
        borderRadius: 0,
        padding: "1rem 1.5rem 1rem 1.5rem"
        // zIndex:999
    },
    coursesContainer: {
        marginTop: "2rem"
    },
    courseItemContainer: {
        textAlign: "center"
    },
    iconContainer: {
        background: "#EFCF59",
        width: "200px",
        height: "200px",
        borderRadius: "50%",
        textAlign: "center",

    },
    icon: {
        margin: "auto",
        height: "200px",
        verticalAlign: "middle",
        color: "white",
        fontSize: "10rem"

    },
    headBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "1.5rem"
    },
    searchCombo: {
        display: "flex",
        // flexDirection: "row-reverse"
    },
    categorySelector: {
    
        width: "120px"
    },
    formControl: {
        margin: "1rem",
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: "6rem",
    },
    typeSelector: {
        marginBottom: "1rem"
    },
    bigTitle: {
        fontWeight: 700,
        color: "#343434",
        marginLeft: "1.5rem",
        paddingBottom: "2rem",
        // marginBottom:"rem",
        borderBottom: "2px solid #767676"

    },
    categoryBlock:
    {
        display: "flex",
        flexDirection: "row-reverse",
        [theme.breakpoints.down('xs')]: {
            marginTop:"1rem",
            flexDirection: "row",
        }
    },



}));
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function Home() {
    const classes = useStyles()
    const [productTypeSelect, setProductTypeSelect] = React.useState(0)
    const [productResult, setProductResult] = React.useState({})
    const [category, setCategory] = React.useState([])
    const [searchBoxValue, setSearchBoxValue] = React.useState(undefined)
    const [searchQuery, setSearchQuery] = React.useState(undefined)
    const [selectedCate, setSelectedCate] = React.useState(null)
    const searchBox = React.useRef(null)
    const getAll = useStore(s => s.getAll)
    const search = useStore(s => s.search)
    const [page, setPage] = React.useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const loadCategory = async () => {
        const result = await getAll("category")
        if (result) {
            console.log(result)
            setCategory(result)

        }
    }
    const searchSubmit = async (e) => {
        e.preventDefault()
        setSearchQuery(e.target.search.value)
    }
    const searchProduct = async () => {
        let result
        let Type = {}
        let Cate = {}
        if (productTypeSelect == 1) {
            Type.Type = 2
        } else if (productTypeSelect == 2) {
            Type.Type = 1
        }
        if (selectedCate) {
            Cate.CategoryId = selectedCate
        }
        result = { ...Type, ...Cate }
        let hasKey = false
        for (let k in result) {
            hasKey = true
            break
        }
        if (hasKey) {
            result = await search("products", searchQuery, undefined, page, result)
        } else {
            result = await search("products", searchQuery, undefined, page)
        }

        if (result) {
            console.log(result)
            setProductResult(result)
        }
    }
    React.useEffect(()=>{searchProduct()}, [selectedCate, searchQuery, productTypeSelect, page])
    React.useEffect(()=>{loadCategory()}, [])
    return (
        <div>
            <Container className={classes.coursesContainer}>
                <Typography variant="h3" className={classes.bigTitle}>
                    IQsem Shop
                </Typography>
                <StyledTabs
                    className={classes.typeSelector}
                    value={productTypeSelect}
                    onChange={(e, v) => {
                        console.log(v)
                        setProductTypeSelect(v)
                    }}
                    aria-label="styled tabs example"
                    TabIndicatorProps={{
                        style: {
                            height: "10px",
                        }
                    }}>
                    <StyledTab autoFocus label="Show All" {...a11yProps(0)} />
                    <StyledTab label="Courses"  {...a11yProps(1)} />
                    <StyledTab label="Textbooks" {...a11yProps(3)} />

                </StyledTabs>
                <Container >
                    <Box className={classes.headBox}>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Box className={classes.searchCombo}>


                                    <form onSubmit={searchSubmit}>
                                        <TextField
                                            fullWidth={window.screen.width < 600}
                                            style={{ marginRight: "1rem" }}
                                            value={searchBoxValue}
                                            onChange={(e) => { setSearchBoxValue(e.target.value) }}
                                            ref={searchBox}
                                            name="search"
                                            type="text"
                                            label="search"
                                            variant="outlined"

                                            size="small"
                                        ></TextField>

                                        <input type="submit" hidden></input>
                                    </form>
                                    <Buttom color="primary"
                                        onClick={() => {
                                            console.log(searchBox.current)
                                            setSearchBoxValue("")
                                            setSearchQuery(undefined)
                                        }}>
                                        Clean&nbsp;Search
</Buttom>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.categoryBlock} >
                                <TextField
                                    select
                                    className={classes.categorySelector}
                                    value={selectedCate}
                                    fullWidth={window.screen.width < 600}
                                    variant="outlined"
                                    onChange={(e) => { setSelectedCate(e.target.value) }}
                                    label="Category"
                                    size="small"
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {category.length > 0 && category.map((v) => {
                                        return <MenuItem value={v._id}>{v.Name}</MenuItem>
                                    })}

                                </TextField>
                            </Grid>

                        </Grid>





                    </Box>
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center">
                        {productResult.data && productResult.data.map((v, i) => {
                            return (
                                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                                    <CourseCard courseInfo={v} />
                                </Grid>)
                        })}
                        <Grid item xs={12} >
                            <Box style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                                <Pagination variant="outlined" count={productResult.metadata ? productResult.metadata.totalPages : 0} page={page} onChange={handlePageChange} shape="rounded" />
                            </Box>
                        </Grid>
                    </Grid>

                </Container>
            </Container>
        </div>
    )
}
